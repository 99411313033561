import {
  AUTHOR_SETTINGS,
  SIGN_IN,
  SIGN_OUT,
  IS_SIGNED_IN,
  IS_LOGGED_IN,
  SET_USER,
  SIGN_UP,
  BUTTON_CLICKED,
  CLEAR_RECOMMENDED,
  DELETE_PIECE,
  DELETE_DRAFT,
  DELETE_DRAFT_CONFIRMATION,
  DISPLAY_MAIN_HEADER,
  DISPLAY_MAIN_HEADER_CHIPS,
  DISPLAY_FOOTER,
  EDITOR_CREATE,
  EDITOR_CHANGE,
  EDITOR_CLEAR,
  EDITOR_DB,
  EDITOR_INTERVAL,
  EDITOR_STATUS,
  OLD_DRAFT_DELETE,
  PIECE_SAVE,
  PIECE_DUPLICATE,
  PUBLISH_DUPLICATE,
  PUBLISH_CONFIRMATION,
  PIECE_UPDATE,
  PIECE_PUBLISH,
  PIECES_GET,
  PROMOTED_GET,
  SUBSCRIBE,
  SAVED_GET,
  CLEAR_SAVED,
  UNSUBSCRIBE,
  UNSUBSCRIBE_CONFIRMATION,
  CLEAR_SUBSCRIPTIONS,
  SUBSCRIPTIONS_GET,
  TRENDING_GET,
  DRAFTS_GET,
  RECOMMENDED_GET,
  REVERT_TO_DRAFT,
  DRAFTS_DELETE,
  EDITOR_SAVE_TITLE,
  EDITOR_DB_TITLE,
  SET_POP_OVER,
  RESET_STORE,
} from "./types";
import { handleVote, hasVote } from "./vote";
import { pauseMenu, handleSaveUI } from "./menus";
import { setAuthor } from './author';
import API from './../utils/api'
import { redirect } from "react-router-dom";

export const updateButtonClickedAuth = button => {
  return {
    type: BUTTON_CLICKED,
    payload: button
  };
};

// this is for all the functionality for when you sign in following a button press that needs authentication
// ? Look into making this a generator function so we can stop and resume?
//FIXME: add back data when we have it from signIn()
// export const handlePostSignInQueue = (data, buttonClicked) => async (dispatch, getState) => {
export const handlePostSignInQueue = (buttonClicked) => async (dispatch, getState) => {
  const state = await getState()
  // if (state.auth.id === state.author.id) return; //FIXME: adjust this comparison // if you are the author don't run the below switch

  // dispatch(hasVote(data)); // updating hasVote in the store 
  console.log('In handlePostSignInQueue - data: ', buttonClicked);
  // ? Confirm if they already did that vote, summon a confirmation modal?
  switch (buttonClicked) {
    case 'write':
      window.location.href = "/#/writersMode";
      break;
    case 'upVote': //TODO: change to 'boosted'
      dispatch(handleVote('upVote'));
      console.log("Up Voted!");
      break;
    case 'downVote': //TODOL remove this case
      dispatch(handleVote('downVote'));
      console.log("Down Voted!");
      break;
    case 'saved':
      API.favorite(state.pieces.currentPiece._id)
        .then(({ data }) => {
          dispatch(pauseMenu());
          dispatch(handleSaveUI(data.hasSaved));
          console.log("Saved!");
        })
      break;
    case 'subscribe':
      API.isSubscribed(state.author.id) // TODO: Get from new author reducer
        .then(({ data }) => {
          console.log('data from handlePostSignInQueue: ', data);
          if (data) {
            dispatch(setSubscribeUI());
            dispatch(UnSubscribeModal()); // shows modal and the modal calls API.unsubscribe()
          } else {
            API.subscribe(state.author.id);
          }
        })
    case 'account':
      console.log('inside account case from handlePostSignInQueue');
      // window.location.href = "/#/account";
      redirect("/#/account");
      break;
    default:
      redirect("/#/account");
    // window.location.href = "/#/account";
  }

  // resets buttonClicked back to null
  dispatch(updateButtonClickedAuth(null));
}

export const signIn = form => async (dispatch, getState) => {
  let state = await getState();
  let buttonClicked = state.auth.buttonClicked;
  const response = await API.signIn(form); // TODO: look into how we can obscure the password before it gets sent to the route
  const signInData = await response.json();
  console.log("====Signing IN ======================");
  console.log("button clicked from signIn: ", buttonClicked);
  console.log('signin response : ', signInData);
  console.log('signin: ', signInData);
  localStorage.setItem("accessToken", signInData.token);
  // signInData.token && localStorage.setItem("isLoggedIn", "true");
  signInData.token && localStorage.setItem("isLoggedIn", signInData.isSignedIn);

  dispatch({ type: SIGN_IN, payload: signInData });
  // return if we dont need to vote
  // if (!buttonClicked) return; //TODO: test if we need this back

  // TODO:get data just for when on pauseMenu - switch statement?
  // TODO:Test to make sure this still works post aws migration
  // FIXME: replace with updated getVote call
  // const { data } = await API.getVote(state.pieces.currentPiece._id);  // ? Can we await this in a dispatch?
  // dispatch(handlePostSignInQueue(data, buttonClicked));
  dispatch(handlePostSignInQueue(buttonClicked));
};

export const signUp = form => async (dispatch, getState) => {
  console.log("===SIGNING UP =============================");
  const response = await API.signUp(form);
  console.log('signup: ', response.data);
  dispatch({ type: SIGN_UP, payload: response.data });
  dispatch(signIn(form));
};

//TODO: remove action and references
export const isSignedIn = response => async (dispatch, getState) => { // response should be true or false
  console.log('response from isSignedIn: ', response);
  await dispatch({ type: IS_SIGNED_IN, payload: response });
  // return {
  //   type: IS_SIGNED_IN,
  //   // payload: { ...response.data }
  //   payload: response
  // };
};

export const isLoggedIn = response => async (dispatch, getState) => { // response should be true or false
  //! look into persisting token
  //! look into persisting react app and or redux
  console.log('response from isLoggedIn: ', response);
  await dispatch({ type: IS_LOGGED_IN, payload: response });
  // return {
  //   type: IS_SIGNED_IN,
  //   // payload: { ...response.data }
  //   payload: response
  // };
};

export const setUserName = username => {
  return {
    type: SET_USER,
    payload: username
  };
};

export const showModal = input => {
  return {
    type: IS_SIGNED_IN,
    payload: { showModal: true }
  };
};

export const closeModal = () => async (dispatch) => {
  dispatch({ type: IS_SIGNED_IN, payload: { showModal: false } });
  dispatch({ type: BUTTON_CLICKED, payload: null });
};

export const closeDuplicateModal = input => {
  return {
    type: PIECE_DUPLICATE,
    payload: { isDuplicate: false }
  };
};

export const closeDuplicatePublishModal = input => {
  return {
    type: PUBLISH_DUPLICATE,
    payload: { isPublishedDuplicate: false }
  };
};

export const closePublishModal = input => {
  return {
    type: PUBLISH_CONFIRMATION,
    payload: { showPublishedConfirmation: false }
  };
};

export const closeDeleteDraftModal = input => {
  return {
    type: DELETE_DRAFT_CONFIRMATION,
    payload: { showDeleteDraftConfirmation: false }
  };
};

export const signOut = () => async (dispatch, getState) => {
  // const response = await API.signOut(); //! Dont need this with AWS (was for passport)

  console.log("== SIGNED Out =============================");

  dispatch({ type: SIGN_OUT, payload: { username: null, isSignedIn: false, id: null } });
  // clear token from local storage
  localStorage.removeItem('accessToken');
  localStorage.removeItem('isLoggedIn');
  // FIXME: Delete this????
  dispatch({ type: UNSUBSCRIBE });
};

export const hideMainHeader = input => {
  return {
    type: DISPLAY_MAIN_HEADER,
    payload: { mainHeader: false }
  };
};

export const showMainHeader = input => {
  return {
    type: DISPLAY_MAIN_HEADER,
    payload: { mainHeader: true }
  };
};

export const showMainHeaderChips = input => {
  return {
    type: DISPLAY_MAIN_HEADER_CHIPS,
    payload: { mainHeaderChips: input }
  };
};

export const hideFooter = input => {
  return {
    type: DISPLAY_FOOTER,
    payload: { footer: false }
  };
};

export const showFooter = input => {
  return {
    type: DISPLAY_FOOTER,
    payload: { footer: true }
  };
};

//  TEXT EDITOR -------------------------------------

export const createEditor = (state) => async dispatch => {
  console.log('/////////////////////////');
  console.log('state from createEditor: ', state);
  console.log('/////////////////////////');
  if (state) {
    let editorState = state.content.join(' ');

    dispatch({ type: EDITOR_SAVE_TITLE, payload: state.title });
    dispatch({ type: EDITOR_DB_TITLE, payload: state.title });
    dispatch({ type: EDITOR_CREATE, payload: { editorState } });
    dispatch({ type: EDITOR_STATUS, payload: "Saved" });
    dispatch({ type: EDITOR_DB, payload: { editorDb: editorState } });
    dispatch({ type: PIECE_SAVE, payload: state });
  } else { // Creates an empty editor
    let editorState = "";

    dispatch({ type: EDITOR_SAVE_TITLE, payload: "" });
    dispatch({ type: EDITOR_CREATE, payload: { editorState } });
    dispatch({ type: EDITOR_STATUS, payload: "" });
    dispatch({ type: EDITOR_DB, payload: { editorDb: editorState } });
  }
};

let count = 0;
export const changeEditor = () => async (dispatch, getState) => {
  let state = await getState();
  console.log('state from changeEditor: ', state);
  var currentEditorState = state.textEditor.editorState;
  let currentTitle = state.textEditor.title;
  let currentDbState = state.textEditor.editorDb;
  let currentDbTitle = state.textEditor.titleDb;
  console.log('title: ', currentTitle);
  console.log('titleDb: ', currentDbTitle);
  if (currentEditorState != currentDbState || currentTitle != currentDbTitle) {
    // dispatch(saveEditorBody(currentEditorState));
    console.log('Stopping Timer BEFORE', state.textEditor.interval);
    clearTimeout(state.textEditor.interval);
    console.log('Stopping Timer AFTER', state.textEditor.interval);

    let interval = setTimeout(() => {
      console.log('SetTimeout Count: ', ++count);
      dispatch(savePiece());
    }, 1000);
    console.log(interval);

    dispatch({ type: EDITOR_INTERVAL, payload: interval });
    dispatch({ type: EDITOR_STATUS, payload: "" });

  } else {
    console.log("Not Saving");
  }
};

export const saveEditorTitle = (title) => {
  return {
    type: EDITOR_SAVE_TITLE,
    payload: title
  }
}

export const saveEditorBody = (body) => {
  return {
    type: EDITOR_CHANGE,
    payload: body
  }
}

export const clearEditor = () => async (dispatch, getState) => {
  let state = await getState();
  clearTimeout(state.textEditor.interval);
  dispatch({ type: EDITOR_CREATE, payload: { editorState: '' } });
  dispatch({ type: EDITOR_DB, payload: { editorDb: '' } });
  dispatch({ type: EDITOR_DB_TITLE, payload: "" });
  dispatch({ type: EDITOR_SAVE_TITLE, payload: "" });
  // TODO: rename to CURRENT_PIECE_CLEAR and have a proper EDITOR_CLEAR mapped to the textEditor reducer
  dispatch({ type: EDITOR_CLEAR, payload: { _id: null, draft: null } });
}

//  TEXT EDITOR -------------------------------------

export const savePiece = () => async (dispatch, getState) => {
  const state = await getState();
  console.log('State from Draft Save: ', state);
  const contentState = state.textEditor.editorState;

  const content = [];
  let arr = contentState.replace(/(\n)/gm, " ").split(' ').filter((char) => char !== '');
  for (let i = 0; i < arr.length; i += 500) {
    const chunk = arr.slice(i, i + 500);
    content.push(chunk.join(' '));
  }
  const draftData = { // content for the piece that we send over
    content,
    title: state.textEditor.title || "Untitled",
    pageCount: content.length
  };
  let pieceId = state.pieces.currentPiece.pieceId || null;
  console.log('Piece ID: ', pieceId);
  dispatch({ type: EDITOR_STATUS, payload: "Saving..." });
  if (pieceId) { //updating draft
    let { data } = await API.updatePiece(pieceId, draftData, state.pieces.currentPiece.SK);
    console.log('DATA from SAVE Piece (IF): ', data);
    dispatch({ type: EDITOR_DB, payload: { editorDb: state.textEditor.editorState } });
    dispatch({ type: EDITOR_DB_TITLE, payload: state.textEditor.title });
    dispatch({ type: EDITOR_STATUS, payload: "Saved" });
  } else { //new draft
    let response = await API.newDraft(draftData);
    let { data } = await response.json();
    console.log("DATA from SAVE Piece (ELSE): ", data);
    dispatch({ type: PIECE_SAVE, payload: data });
    dispatch({ type: EDITOR_DB, payload: { editorDb: state.textEditor.editorState } });
    dispatch({ type: EDITOR_DB_TITLE, payload: state.textEditor.title });
    dispatch({ type: EDITOR_STATUS, payload: "Saved" });
  }
};

export const savePieceLeave = () => async (dispatch, getState) => {
  const state = await getState();
  const contentState = state.textEditor.editorState;
  // const axiosData = { // content for the piece that we send over
  //   content: JSON.stringify(convertToRaw(contentState)),
  //   title: state.textEditor.title
  // };
  console.log('tile: ', state.textEditor.title);
  // FIXME: finish logic untitled titles with auto numbers and body and titles with no body
  // Iterate over drafts array
  if (contentState) {
    console.log('contentState: ', contentState);
  }
}

export const deleteDraftConfirmation = contentState => async (dispatch, getState) => {
  dispatch({ type: DELETE_DRAFT_CONFIRMATION, payload: { showDeleteDraftConfirmation: true } });
}

export const deletePiece = (pieceId, draft) => async (dispatch, getState) => {
  const state = await getState();
  let { data } = await API.deletePiece(pieceId, state.pieces.currentPiece.SK);
  if (draft) {
    // Close modal after delete
    dispatch({ type: DELETE_DRAFT_CONFIRMATION, payload: { showDeleteDraftConfirmation: false } }); //TODO: Rename to have modal at the end
  } else {
    // unpublish here
    // await API.updatePiece(pieceId, { unPublished: true });
    dispatch({ type: CLEAR_RECOMMENDED });
  }
}

export const deletePieceModal = () => ({ type: DELETE_PIECE })

export const authorSettings = () => ({ type: AUTHOR_SETTINGS })

export const revertPublishedPiece = (pieceId, redirect) => async (dispatch, getState) => {
  const state = await getState();
  //TODO: Do we still need content?
  await API.revertPublishedPiece(pieceId);
  dispatch({ type: CLEAR_RECOMMENDED })
  dispatch({ type: REVERT_TO_DRAFT }) // closes the revert to draft modal
  redirect();
}

export const revertPieceToDraft = () => ({ type: REVERT_TO_DRAFT }) //MODAL

export const publishConfirmation = contentState => async (dispatch, getState) => {
  dispatch({ type: PUBLISH_CONFIRMATION, payload: { showPublishedConfirmation: true } })
}

//FIXME: Clear editor not being called in the right oder? (8/2 - Sun)
export const publishPiece = () => async (dispatch, getState) => {
  const state = await getState();
  const contentState = await state.textEditor.editorState;
  const axiosData = { // content for the piece that we send over
    content: contentState,
    title: state.textEditor.title.trim(),
    pageCount: state.pieces.currentPiece.pageCount,
    published: 1,
    draft: 0,
    created: Date.now(),
  };
  let draftId = state.pieces.currentPiece.pieceId;
  let isDraft = state.pieces.currentPiece.draft;
  let SK = state.pieces.currentPiece.SK;
  console.log('Draft ID inside publishPiece(): ', draftId);
  if (isDraft) {
    let { data } = await API.publishPiece(draftId, axiosData, SK);
    console.log("DATA from PUBLISH Piece (ELSE): ", data);
    if (!data.status) { //for duplicate published pieces
      dispatch({ type: PUBLISH_CONFIRMATION, payload: { showPublishedConfirmation: false } });
      dispatch({ type: PIECE_PUBLISH, payload: data.data });
      dispatch({ type: OLD_DRAFT_DELETE, payload: { oldDraftToDeleteId: draftId } });
      dispatch({ type: PUBLISH_DUPLICATE, payload: { isPublishedDuplicate: true } });
      dispatch({ type: CLEAR_RECOMMENDED });
    } else {
      dispatch({ type: PIECE_PUBLISH, payload: data.data });
      dispatch({ type: EDITOR_CLEAR, payload: { _id: null, draft: null } });
      dispatch({ type: EDITOR_DB_TITLE, payload: "" });
      dispatch({ type: PUBLISH_CONFIRMATION, payload: { showPublishedConfirmation: false } });
      dispatch({ type: CLEAR_RECOMMENDED });
      redirect();
    }
  }
};

export const publishOverride = redirect => async (dispatch, getState) => {
  const state = await getState();
  console.log('State from Publish Override: ', state);
  const contentState = await state.textEditor.editorState;
  let pieceId = state.pieces.currentPiece._id;
  let draftId = state.pieces.oldDraftToDeleteId;
  console.log('Piece ID from publishOverride(): ', pieceId);
  const axiosData = { // content for the piece that we send over
    content: contentState,
    title: state.textEditor.title,
  };
  let { data } = await API.publishOverride(pieceId, draftId, axiosData);
  dispatch({ type: PUBLISH_DUPLICATE, payload: { isPublishedDuplicate: false } });
  dispatch({ type: EDITOR_CLEAR, payload: { _id: null, draft: null } });
  dispatch({ type: EDITOR_DB_TITLE, payload: "" });
  redirect();
}

// For displaying and closing the duplicate modal
// import in the modal like closeModal
export const pieceDuplicate = contentState => async (dispatch, getState) => {
  dispatch({ type: PIECE_DUPLICATE, payload: { isDuplicate: true } })
}

// FIXME: Separate to a pieces actions js
export const getPublished = (token) => async dispatch => {
  let response = await API.getPublishedPieces(token);
  console.log('response from getPublished: ', response);
  let { data } = await response.json();
  //FIXME: data does not contain authorName obj but we are getting it on the server
  console.log('data from getPublished: ', data);

  dispatch({ type: PIECES_GET, payload: data });
  // dispatch({ type: EDITOR_CLEAR, payload: { _id: null, draft: null } });
  // dispatch({ type: EDITOR_DB_TITLE, payload: "" });  
};

//! getting 403 from lamda 
//! not showing up in cloudfront
export const getDrafts = (token) => async (dispatch, getState) => {
  console.log('getDrafts Token: ', token)
  // const { auth: { username } } = await getState();
  // console.log('username from getDrafts action: ', username);
  let response = await API.getDrafts({ token });
  let { data } = await response.json();
  console.log('data from getDrafts: ', data);

  dispatch({ type: DRAFTS_GET, payload: data });
  // dispatch({ type: EDITOR_CLEAR, payload: { _id: null, draft: null } });
  // dispatch({ type: EDITOR_DB_TITLE, payload: "" });  
};

export const getRecommended = () => async (dispatch, getState) => {
  const state = await getState();
  console.log('state from getRecommended (action): ', state);
  let response = await API.getRecommended(state.pieces.recommended?.LastEvaluatedKey);
  let { data } = await response.json();
  console.log('Recommended pieces: ', data);

  dispatch({ type: RECOMMENDED_GET, payload: data });
};

export const getTrending = (page) => async dispatch => {
  let { data } = await API.getTrending(page);
  console.log('Trending pieces: ', data);

  dispatch({ type: TRENDING_GET, payload: data });
};

export const clearRecommended = () => {
  return {
    type: CLEAR_RECOMMENDED,
  };
};

export const getSaved = (page) => async dispatch => {
  let { data } = await API.getSaved(page);
  console.log('Saved pieces: ', data);

  dispatch({ type: SAVED_GET, payload: data });
};

export const clearSaved = () => {
  return {
    type: CLEAR_SAVED,
  };
};

export const getPromoted = () => async dispatch => {
  let { data } = await API.getPromoted();
  console.log('Promoted piece: ', data);

  dispatch({ type: PROMOTED_GET, payload: data });
};

// Reader's and Preview Mode
export const loadEditor = (currentPiece) => async dispatch => {
  let content = currentPiece.content;
  console.log('currentPiece from loadEditor: ', currentPiece);
  console.log('content from loadEditor: ', content);
  // content = JSON.parse(content);
  // const contentState = await convertFromRaw(content); // wont load contents without await - WHY?
  // const editorState = EditorState.createWithContent(contentState);
  dispatch({ type: EDITOR_CREATE, payload: { editorState: content } });
  dispatch({ type: PIECE_SAVE, payload: currentPiece });
};

//TODO: Move subscription actions to own file?
export const subscribe = (authorId) => async (dispatch, getState) => {
  await API.subscribe(authorId);
  const { data } = await API.getUserInfo(authorId);
  dispatch(setAuthor(data));
  dispatch(setSubscribeUI());
};

export const unSubscribe = (authorId) => async (dispatch, getState) => {
  await API.unSubscribe(authorId);
  const { data } = await API.getUserInfo(authorId);
  dispatch(setAuthor(data));
  dispatch(setUnSubscribeUI());
};

export const getSubscriptions = (page) => async dispatch => {
  let { data } = await API.getSubscriptions(page);
  console.log('Subscriptions: ', data);

  dispatch({ type: SUBSCRIPTIONS_GET, payload: data });
};

export const clearSubscriptions = () => {
  return {
    type: CLEAR_SUBSCRIPTIONS,
  };
};


export const UnSubscribeModal = () => ({ type: UNSUBSCRIBE_CONFIRMATION })
export const setSubscribeUI = () => ({ type: SUBSCRIBE })
export const setUnSubscribeUI = () => ({ type: UNSUBSCRIBE })

export const resetStore = () => ({ type: RESET_STORE })

export const setPopOver = (isPopOver) => {
  return {
    type: SET_POP_OVER,
    payload: isPopOver
  }
}