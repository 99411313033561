import React from "react";
import moment from "moment";
import styles from "./cardInfo.module.scss";

function CardInfo(props) {
  return (
    <h4 class={styles.cardInfo}>
      {props.isDraft || ""}
      {/* Change later to dynamic data */}
      {moment(props.created).fromNow()} / 3.8 M reads / {props.pageCount} pages
    </h4>
  );
}

export default CardInfo;
