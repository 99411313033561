import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { hideFooter, isSignedIn, showFooter, showModal } from "../actions";
import { handleVote } from "../actions/vote";
import { previewPauseMenu } from "../actions/menus";
import PreviewPauseMenuHeader from "./../components/PreviewPauseMenuHeader/PreviewPauseMenuHeader";
import DeleteDraftModal from "./../components/DeleteDraftModal";
import PublishModal from "./../components/PublishModal";

import TextEditor from "../components/TextEditor/TextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCheck,
  faComments,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./PauseMenu.module.scss"; // TODO: Create separate css for this?

class PreviewPauseMenu extends Component {
  componentDidMount() {
    this.props.hideFooter();
  }

  render() {
    return (
      <>
        <PreviewPauseMenuHeader />
        <div
          className={styles.pauseMenuContainer}
          onClick={this.props.previewPauseMenu}
        >
          <div className={styles.pauseMenuContents}>
            <div className={styles.pageNumberContainer}>
              <h3>Page:</h3>
              <h2>#/#</h2>
            </div>
            <div className={styles.authorIconsContainer}>
              <div className={styles.seeMoreContainer}>
                <p>See me more by (Author)</p>
              </div>
              <div className={styles.pauseMenuIconsContainer}>
                {/* TODO: Figure out how to dismiss the pause menu only when clicking the overlay in a better way. */}
                <span
                  className="fa-layers fa-fw"
                  onClick={this.props.previewPauseMenu}
                >
                  <FontAwesomeIcon
                    className={styles.pauseMenuCircle}
                    icon={faCircle}
                    size="lg"
                  />
                  <FontAwesomeIcon
                    className={styles.pauseMenuSaved}
                    icon={faCheck}
                    color="white"
                    size="sm"
                  />
                </span>
                <span
                  className="fa-layers fa-fw"
                  onClick={this.props.previewPauseMenu}
                >
                  <FontAwesomeIcon
                    className={styles.pauseMenuCircle}
                    icon={faCircle}
                    size="lg"
                  />
                  <FontAwesomeIcon
                    className={styles.pauseMenuComments}
                    icon={faComments}
                    color="white"
                    size="sm"
                  />
                </span>
                <span
                  className="fa-layers fa-fw"
                  onClick={this.props.previewPauseMenu}
                >
                  <FontAwesomeIcon
                    className={styles.pauseMenuCircle}
                    icon={faCircle}
                    size="lg"
                    color="#222222"
                  />
                  <FontAwesomeIcon
                    className={styles.pauseMenuUpvote}
                    icon={faChevronUp}
                    color="white"
                    size="sm"
                  />
                </span>
                <span
                  className="fa-layers fa-fw"
                  onClick={this.props.previewPauseMenu}
                >
                  <FontAwesomeIcon
                    className={styles.pauseMenuCircle}
                    icon={faCircle}
                    size="lg"
                    color="#222222"
                  />
                  <FontAwesomeIcon
                    className={styles.pauseMenuDownVote}
                    icon={faChevronDown}
                    color="white"
                    size="sm"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
  };
};
export default connect(mapStateToProps, {
  hideFooter,
  showFooter,
  previewPauseMenu,
  showModal,
})(PreviewPauseMenu);
