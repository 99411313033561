import React from 'react'
import { connect } from "react-redux";
import { Navigate, Outlet } from 'react-router-dom';
import AuthModal from '../authModal/index'

const ProtectedRoutes = (props) => {
  console.log('ProtectedRoutes Ran!');
  //! make sure redux is updated for isLoggedIn when signing in or use local storage here instead?
  return (props.isSignedIn ? <Outlet /> : <AuthModal />)
  // return (isSignedIn ? <Outlet /> : Auth.federatedSignIn())
};

const mapStateToProps = state => {
  console.log('mapStateToProps from protectedRoutes.js: ', state.auth);
  return {
    isSignedIn: state.auth.isSignedIn, //!link to local storage??
  };
};

export default connect(mapStateToProps)(ProtectedRoutes);