import React, { Component } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { deletePiece, closeDeleteDraftModal, clearEditor } from "../../actions";
import styles from "./index.module.scss";

const DeleteDraftModal = (props) => {

  const navigate = useNavigate();

  //TODO: clean up old class component props (redirect)
  const handleDelete = () => {
    props.deletePiece(
      props.currentPiece.pieceId,
      props.currentPiece.draft,
    ).then(()=>navigate("/writersMode"));
  };

  return (
    <div className={styles.bgModal}>
      <div className={styles.modalContents}>
        <h4>Are you sure you want to delete this draft?</h4>
        <input
          onClick={handleDelete}
          className={styles.deleteButton}
          value="Delete"
        />
        {/* TODO: Rename */}
        <div className={styles.closeDuplicateModal}>
          <a href="#" onClick={props.closeDeleteDraftModal}>
            Nevermind, take me back
          </a>
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = (state) => ({
  currentPiece: state.pieces.currentPiece,
});

export default connect(mapStateToProps, {
  closeDeleteDraftModal,
  deletePiece,
  clearEditor,
})(DeleteDraftModal);
