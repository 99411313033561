import React, { Component } from "react";
import { connect } from "react-redux";
import {
  closeModal,
  publishPiece,
  closePublishModal,
  clearEditor,
} from "../../actions";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";

const PublishModal = (props) => {

  const navigate = useNavigate();

  //TODO: clean up old class component props (redirect)
  const handlePublish = () => {
    props.publishPiece().then(()=>navigate("/writersMode"));
  };

  return (
    <div className={styles.bgModal}>
      <div className={styles.modalContents}>
        <h4>Are you sure you want to publish?</h4>
        <input
          onClick={handlePublish}
          className={styles.publishButton}
          value="Publish!"
        />
        <div className={styles.closeDuplicateModal}>
          <a href="#" onClick={props.closePublishModal}>
            Nevermind, take me back
          </a>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  closePublishModal,
  publishPiece,
  clearEditor,
})(PublishModal);
