export const AUTHOR_SETTINGS = "AUTHOR_SETTINGS";
export const SHOW_PAUSE_MENU = "SHOW_PAUSE_MENU";
export const SHOW_PAUSE_MENU_SUBSCRIBE_BUTTON = "SHOW_PAUSE_MENU_SUBSCRIBE_BUTTON";
export const SHOW_PREVIEW_PAUSE_MENU = "SHOW_PREVIEW_PAUSE_MENU";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP = "SIGN_UP";
export const IS_SIGNED_IN = "IS_SIGNED_IN";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const SET_USER = "SET_USER";
export const BUTTON_CLICKED = "BUTTON_CLICKED";
export const CLEAR_RECOMMENDED = "CLEAR_RECOMMENDED";
export const DISPLAY_MAIN_HEADER = "DISPLAY_MAIN_HEADER";
export const DISPLAY_MAIN_HEADER_CHIPS = "DISPLAY_MAIN_HEADER_CHIPS";
export const DISPLAY_FOOTER = "DISPLAY_FOOTER";
export const DELETE_DRAFT_CONFIRMATION = "DELETE_DRAFT_CONFIRMATION";
export const DELETE_PIECE = "DELETE_PIECE";
export const DELETE_DRAFT = "DELETE_DRAFT";

export const EDITOR_CREATE = "EDITOR_CREATE";
export const EDITOR_CLEAR = "EDITOR_CLEAR";
export const EDITOR_DB = "EDITOR_DB";
export const EDITOR_INTERVAL = "EDITOR_INTERVAL";
export const EDITOR_SAVE = "EDITOR_SAVE";
export const EDITOR_STATUS = "EDITOR_STATUS";
export const EDITOR_FOCUS = "EDITOR_FOCUS";
export const EDITOR_CHANGE = "EDITOR_CHANGE";
export const EDITOR_CURRENT_CONTENT = "EDITOR_CURRENT_CONTENT";
export const EDITOR_SAVE_TITLE = "EDITOR_SAVE_TITLE"
export const EDITOR_DB_TITLE = "EDITOR_DB_TITLE"

export const PAUSE_MENU_LEAVE = "PAUSE_MENU_LEAVE";
export const PIECE_SAVE = "PIECE_SAVE";
// TODO: Rename to DRAFT_DUPLICATE
export const PIECE_DUPLICATE = "PIECE_DUPLICATE";
export const PUBLISH_DUPLICATE = "PUBLISH_DUPLICATE";
export const PUBLISH_CONFIRMATION = "PUBLISH_CONFIRMATION";
export const PIECE_UPDATE = "PIECE_UPDATE";
export const PIECES_GET = "PIECES_GET";
export const PROMOTED_GET = "PROMOTED_GET";
// Trending
export const TRENDING_GET = "TRENDING_GET";
// Subscriptions
export const SUBSCRIBE = "SUBSCRIBE";
export const SUBSCRIPTIONS_GET = "SUBSCRIPTIONS_GET";
export const CLEAR_SUBSCRIPTIONS = "CLEAR_SUBSCRIPTIONS";
export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const UNSUBSCRIBE_CONFIRMATION = "UNSUBSCRIBE_CONFIRMATION";
//
export const DRAFTS_GET = "DRAFTS_GET";
export const RECOMMENDED_GET = "RECOMMENDED_GET";
export const REVERT_TO_DRAFT = "REVERT_TO_DRAFT";
export const DRAFTS_DELETE = "DRAFTS_DELETE";
export const OLD_DRAFT_DELETE = "OLD_DRAFT_DELETE";
export const PIECE_PUBLISH = "PIECE_PUBLISH";
export const SET_POP_OVER = "SET_POP_OVER";
// VOTE
export const UP_VOTE = "UP_VOTE";
export const DOWN_VOTE = "DOWN_VOTE";
export const UPDATE_UP_VOTE = "UPDATE_UP_VOTE";
export const UPDATE_DOWN_VOTE = "UPDATE_DOWN_VOTE";
export const CURRENT_VOTE = "CURRENT_VOTE";
export const HAS_VOTE = "HAS_VOTE";
export const HAS_SAVED = "HAS_SAVED";
// AUTHOR
export const SET_AUTHOR = "SET_AUTHOR";
// SAVED
export const SAVED_GET = "SAVED_GET";
export const CLEAR_SAVED = "CLEAR_SAVED";
// To reset the store
export const RESET_STORE = "RESET_STORE";
