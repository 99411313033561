import React from "react";
import Title from "../Title/title";
import CardInfo from "../CardInfo/CardInfo";
import moment from "moment";
// import Summary from '../Summary/summary';
import styles from "./Card.module.scss";

const Card = (props) => {
  return (
    // This classname is unused! convert to {styles.cardContainer} when using
    <div className="cardContainer">
      <div className={styles.card}>
        <Title title={props.title} />
        {/* TODO: Change to Author */}
        {/* <h4 className="cardPostDate">{moment(props.created).fromNow()}</h4> */}
        {/* //TODO: Rename class */}
        <h4 className={styles.cardPostDate}>{props.author}</h4>
        {/* TODO: Rename to PieceInfo */}
        <CardInfo
          isDraft={props.draftPrefix}
          created={props.created}
          pageCount={props.pageCount}
        />
        {/* <Summary/> */}
      </div>
    </div>
  );
};

// const mapStateToProps = state => ({ recommended: state.pieces.recommended });

// export default connect(mapStateToProps, {})(Recommended);

export default Card;
