import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import logo from '../../assets/images/logo_full.png'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import styles from "./header.module.scss";
import { updateButtonClickedAuth } from "../../actions";

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const handleChipClick = () => {
  console.info('You clicked the Chip.');
};

const HideAppBar = (props) => {

  // const shouldMount = useRef(true);

  // useEffect(() => {
  //   // Adds an event listener when the component is mount.
  //   window.addEventListener("scroll", this.handleScroll);
  //   if (shouldMount.current) {
  //     return () => {
  //       // Remove the event listener when the component is unmount.
  //       window.removeEventListener("scroll", this.handleScroll);
  //     };
  //   }
  // })

  // Hide or show the menu.
  // handleScroll = () => {
  //   const { prevScrollpos } = this.state;

  //   const currentScrollPos = window.pageYOffset;
  //   const visible = prevScrollpos > currentScrollPos;

  //   this.setState({
  //     prevScrollpos: currentScrollPos,
  //     visible,
  //   });
  // };

  const setButtonClicked = () => {
    //TODO: Look into redirecting with AWS from the Hosted UI sign in Page
    //TODO: Test google account flow - maybe tokens are named differently, may impact protected route component
    props.updateButtonClickedAuth("account")
  }

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar id={props.mainHeaderChips ? styles.headerChips : styles.header} sx={{position: "sticky"}}>
          <div id={styles.icons}>
            <Link className={styles.homeLogo} to="/">
              {/* <p id={styles.navBarBrand}>Writ.ink</p> */}
              <img id={styles.navBarBrand} src={logo} />
            </Link>
            <Link to='/account'>
              <IconButton onClick={setButtonClicked} aria-label="search" sx={{ marginRight: "24px", height: 32, width: 32 }}>
                <AccountCircleIcon sx={{ fontSize: "32px" }} />
              </IconButton>
            </Link>
          </div>
          {props.mainHeaderChips && <div className={styles.pillsContainer}>
            <Box
              sx={{
                display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                // width: '100%',
                // paddingLeft: "16px",
                // paddingRight: "16px",
                // border: (theme) => `1px solid ${theme.palette.divider}`,
                // borderRadius: 1,
                // bgcolor: 'background.paper',
                // color: 'text.secondary',
                // '& svg': {
                //   m: 1.5,
                // },
                // '& hr': {
                //   mx: 0.5,
                // },

              }}
            >
              <Chip label="All" active onClick={handleChipClick} variant="outlined" sx={{ marginRight: "8px", backgroundColor: "#F5F5F5" }} />
              <Divider orientation="vertical" variant="middle" flexItem />
              <Chip label="Top Boosted" variant="outlined" onClick={handleChipClick} sx={{ marginRight: "8px", marginLeft: "8px" }} />
              <Chip label="Subscriptions" variant="outlined" onClick={handleChipClick} sx={{ marginRight: "8px" }} />
              <Chip label="Saved" variant="outlined" onClick={handleChipClick} />
            </Box>
          </div>
          }
        </AppBar>
      </HideOnScroll>
    </>
  );
}

const mapStateToProps = state => {
  console.log('auth state from HideAppBar.js: ', state.auth);
  return {
    isLoggedIn: state.auth.isSignedIn,
    mainHeaderChips: state.menus.mainHeaderChips,
  };
};

export default connect(mapStateToProps, { updateButtonClickedAuth })(HideAppBar);
