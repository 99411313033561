import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Collections from "../components/profile/Collections/Collections";
import { isSignedIn, signOut, resetStore } from "../actions";
import classnames from "classnames";
import API from '../utils/api';

import UserInfo from "../components/profile/userInfo/userInfo";

import styles from "./account.module.scss";

const Account = (props) => {
  const navigate = useNavigate(); //TODO: remove

  const handleSignOut = async () => {
    try {
      await props.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const handleRefreshToken = () => {
    API.refreshTokenLevel1();
  };

  return (
    <>
      <button onClick={handleRefreshToken}>REFRESH TOKEN TEST</button>
      <UserInfo history={props.history} buttonName="Edit Profile" authorId={props.authorId} />
      <div id={styles.writersModeDiv}>
        <h1 className={styles.sectionTitles}>Writer's Mode</h1>
        <div id={styles.writersModeButtonContainer}>
          <Link className="icon noSelect" to="/writersMode">
            <button id={styles.writersModeButton}>Write</button>
          </Link>
        </div>
      </div>
      <div id={styles.collectionsDiv}>
        <h1 className={styles.sectionTitles}>Collections</h1>
        <div className={styles.collectionsContainer}>
          <Collections />
          <Collections />
          <Collections />
        </div>
      </div>
      <div id={styles.settingsDiv}>
        <h1 className={styles.sectionTitles}>Settings</h1>
        <div id={styles.darkModeDiv}>
          <p>Dark Mode</p>
          <label className={styles.switch}>
            <input type="checkbox" />
            <span className={classnames(styles.slider, styles.round)}></span>
          </label>
        </div>
        <div id={styles.signOutButtonContainer}>
          <a className={classnames(styles.icon, styles.noSelect)}>
            <button onClick={() => handleSignOut()} id={styles.signOutButton}>
              Sign Out
            </button>
          </a>
        </div>
        <div id={styles.deleteAccountButtonContainer}>
          <button id={styles.deleteAccountButton}>Delete Account</button>
        </div>
      </div>
      <Link className={styles.aboutLink} to="/about">
        <p>About Writ.ink</p>
      </Link>
    </>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.auth.accessToken,
  authorId: state.auth.id,
});

export default connect(mapStateToProps, { isSignedIn, signOut, resetStore })(
  Account
);
