import React, { Component } from "react";
import Card from "../../piece/Card/Card";
import { Link } from "react-router-dom";
import styles from "./UserCollections.module.scss";

class UserCollections extends Component {
  render() {
    return (
      <>
        {/* TODO - Some sort of loop will go here to iterate over the relevant pieces from the db */}
        <h4 className="sectionHeaders">Your Collections</h4>
        <h5 className={styles.userCollectionsMessage}>
          You have no collections!
        </h5>
        {/* <Card/>
        <Card/>
        <Link>Load More</Link> */}
      </>
    );
  }
}

export default UserCollections;
