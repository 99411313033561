import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "../../piece/Card/Card";
import { Link } from "react-router-dom";
import { getPublished } from "../../../actions";
import styles from "./UserPublished.module.scss";

class UserPublished extends Component {
  componentDidMount() {
    let token = localStorage.getItem(`accessToken`);
    this.props.getPublished(token);
  }
  render() {
    return (
      <>
        {/* TODO - Some sort of loop will go here to iterate over the relevant pieces from the db */}
        <h4 className="sectionHeaders">Your Published Pieces</h4>
        {this.props.pieces.map((piece) =>
          piece.published ? (
            <Link className="readersModeLink" to={`/piece/${piece._id}`}>
              <Card title={piece.title} created={piece.created} pageCount={piece.pageCount}/>
            </Link>
          ) : (
            ""
          )
        )}
        <div className={styles.loadMorePiecesContainer}>
          <Link className={styles.loadMorePiecesLink} to="#">
            Load More
          </Link>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ pieces: state.pieces.pieces });

export default connect(mapStateToProps, { getPublished })(UserPublished);
