import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { getSubscriptions } from '../actions/index'
import { Link } from 'react-router-dom';
import Card from '../components/piece/Card/Card';
import useWindowScrollPosition from "./../hooks/useWindowScrollPosition";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import styles from "./subscriptions.module.scss";

const Subscriptions = (props) => {

    let [page, setPage] = useState(1);

    const scrollRef = useBottomScrollListener(() => handleEndOfPage());
    useWindowScrollPosition('Subscriptions_ScrollTop', true, scrollRef) // TODO: Clear on sign out

    useEffect(() => {
        if (props.subscriptions.length == 0) { //Subscriptions empty
            localStorage.setItem("Subscriptions_ScrollTop", 0);
            console.log(
                "The Subscriptions array exists (INSIDE IF): ",
                props.subscriptions.length == 0
            );
            props.getSubscriptions(page);
        }
    }, [])
    const handleEndOfPage = () => {
        // TODO: show loading spinner and make fetch request to api
        page = page + 1;
        props.getSubscriptions(page);
        setPage(page); // when setPage finishes turn off spinner/loader
    }

    return (
        <>
            {/* // ? Make new component to combine with saved page? */}
            {
                props.subscriptions.length > 0 ?
                    <div ref={scrollRef} className={styles.subscriptionsTab}>
                        <h4 id="subscriptionTitle" className="sectionHeaders">Subscriptions</h4>
                        {props.subscriptions.map(subscriptions => (
                            <Link className="readersModeLink" to={`/piece/${subscriptions.id}`}>
                                <Card
                                    title={subscriptions.title}
                                    author={subscriptions.author}
                                    created={subscriptions.created}
                                />
                            </Link>
                        ))}
                    </div> :
                    <div className={`container ${styles.noSubContainer}`}>
                        <h4>You are not subscribed to anyone yet!</h4>
                        <p className={styles.noSubText} >Once you subscribe to an author, their pieces will show up here.</p>
                        <button
                            style={{ backgroundColor: "red", color: "white" }}
                            id={styles.emptySubButton}
                        // onClick={}
                        >
                            Search for authors or topics
                        </button>
                    </div>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        subscriptions: state.subscriptions.subscriptions,
    };
};
export default connect(mapStateToProps, { getSubscriptions })(Subscriptions);