import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  authorSettings,
  getPublished, //TODO: DELETE
  hideMainHeader,
  hideFooter,
  showMainHeader,
  showFooter,
  loadEditor,
  clearEditor,
  setSubscribeUI,
  setUnSubscribeUI,
  UnSubscribeModal,
  isSignedIn,
} from "../actions";
import { pauseMenu, handleSaveUI } from "../actions/menus";
import { hasVote } from "../actions/vote";
import { setAuthor } from "./../actions/author";
// TODO: DELETE this component
import Carousel from '../components/Carousel/carousel'
import ReaderModeContent from "../components/ReaderModeContent/ReaderModeContent";
import PauseMenu from './PauseMenu'
import AuthorSettingsModal from './../components/AuthorSettingsModal/index';
import RevertToDraftModal from './../components/RevertDraftModal/index';
import DeletePieceModal from './../components/DeletePieceModal/index'
import API from './../utils/api'

import styles from "./ReadersMode.module.scss";

// TODO: Convert to function component
const ReaderMode = (props) => {
  const shouldMount = useRef(true);
  const { id } = useParams();

  useEffect(() => {
    console.log("useEffect Ran!", shouldMount);
    if (shouldMount.current) {
      console.log("Inside shouldMount if useEffect!", shouldMount);
      shouldMount.current = false;

      props.hideMainHeader();
      props.hideFooter();

      const fetchData = async () => {
        //! how to get authorId for a certain piece?
        //! .filter on Items based on params.id?
        console.log("id from readersmode: ", id);
        // ? Break out this call into an action?
        const response = await API.getPiece(id);
        let { data } = await response.json();
        console.log("data from readers mode", data);
        // TODO: restructure response from the api route to return only what we need
        // TODO: Remove isAuthor - no longer being used?
        const {
          pieceId,
          created,
          userName,
          content,
          title,
          draft,
          //! figure out how to do these with aws
          // hasSaved,
          // hasVote,
          // isAuthor,
        } = data;
        // props.handleSaveUI(hasSaved);
        // props.hasVote(hasVote);
        const currentPiece = {
          pieceId,
          created,
          userName,
          // isAuthor,
          content,
          title,
          draft,
        };
        console.log("currentPiece from ReaderMode", currentPiece);
        props.loadEditor(currentPiece);
        // isSubscribed(currentPiece.authorName.pieceId);
        return () => {
          console.log("ReaderMode Unmounted!");
          props.showMainHeader();
          props.showFooter();
          props.clearEditor();
        };
      };

      fetchData().catch(console.error);
    }
  });

  // async componentDidMount() {
  //   this.props.hideMainHeader();
  //   this.props.hideFooter();

  //   const {
  //     match: { params },
  //   } = this.props;

  //   //! how to get authorId for a certain piece?
  //   //! .filter on Items based on params.id?
  //   console.log('params.id from readersmode: ', params.id);
  //   // ? Break out this call into an action?
  //   const response = await API.getPiece(params.id);
  //   let { data } = await response.json();
  //   console.log('data from readers mode', data);
  //   // TODO: restructure response from the api route to return only what we need
  //   // TODO: Remove isAuthor - no longer being used?
  //   const {
  //     pieceId,
  //     created,
  //     userName,
  //     content,
  //     title,
  //     draft,
  //     //! figure out how to do these with aws
  //     // hasSaved,
  //     // hasVote,
  //     // isAuthor,
  //   } = data;
  //   // this.props.handleSaveUI(hasSaved);
  //   // this.props.hasVote(hasVote);
  //   const currentPiece = {
  //     pieceId,
  //     created,
  //     userName,
  //     // isAuthor,
  //     content,
  //     title,
  //     draft,
  //   };
  //   this.props.loadEditor(currentPiece);
  //   // this.isSubscribed(currentPiece.authorName.pieceId);
  // }

  // componentWillUnmount() {
  //   this.props.showMainHeader();
  //   this.props.showFooter();
  //   this.props.clearEditor();
  // }

  // isSubscribed = async (authorId) => {
  //   this.props.setAuthor({ id: authorId });
  //   console.log(this.props.isSignedIn);
  //   let token = localStorage.getItem("accessToken");
  //   const response = await API.getAuth({
  //     headers: {
  //       authorization: `Bearer ${token}`,
  //     },
  //   });
  //   console.log("response from app.js: ", response);
  //   this.props.isSignedIn(response);

  //   if (response.data.isSignedIn) {
  //     API.isSubscribed(authorId).then(({ data }) => {
  //       console.log("isSubscribed from ReadersMode: ", data);
  //       if (data) {
  //         this.props.setSubscribeUI();
  //       } else {
  //         this.props.setUnSubscribeUI();
  //       }
  //     });
  //   }
  // };

  const redirect = () => {
    props.history.push("/WritersMode");
  };

  // showPauseMenu = (e) => {
  //   console.log("event from readers mode: ", e);
  //   console.log("event.target from readers mode: ", e.target);
  //   let isDisabled = e.target.getAttribute("data-disablePauseMenu");
  //   console.log("isDisabled from readers mode: ", isDisabled);
  //   if (!isDisabled) {
  //     this.props.pauseMenu();
  //   }
  // };

  return (
    <>
      {/* {this.props.pieces.showAuthorSettings && <AuthorSettingsModal />}
        {this.props.pieces.showRevertToDraft && (
          <RevertToDraftModal redirect={this.redirect} />
        )}
        {this.props.pieces.showDeletePiece && (
          <DeletePieceModal redirect={this.redirect} />
        )} */}
      {props.pieces.currentPiece.title ? (
        <div
          className={styles.readersModeContainer}
          // onClick={showPauseMenu}
        >
          <ReaderModeContent editorState={props.editorState} />
        </div>
      ) : null}
      {/* {this.props.showPauseMenu ? (
          <PauseMenu pieceId={this.props.pieces.currentPiece.pieceId} />
        ) : (
          <></>
        )} */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showPauseMenu: state.menus.pauseMenu,
    pieces: state.pieces,
    editorState: state.textEditor.editorState,
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.id,
    authorId: state.author.id,
  };
};

export default connect(mapStateToProps, {
  authorSettings,
  getPublished,
  pauseMenu,
  hideMainHeader,
  hideFooter,
  showMainHeader,
  showFooter,
  hasVote,
  loadEditor,
  clearEditor,
  handleSaveUI,
  setSubscribeUI,
  setUnSubscribeUI,
  UnSubscribeModal,
  setAuthor,
  isSignedIn,
})(ReaderMode);
