import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../actions";

class SignIn extends Component {
  state = {
    username: "",
    password: ""
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  submit = () => {
    this.props.signIn(this.state);
  };

  render() {
    console.log(this.state);
    return (
      <div>
        <h3>SignIn </h3>
        <h5>User Name</h5>
        <input
          type="text"
          name="username"
          value={this.state.username}
          onChange={this.onChange}
        />
        <h5>Password</h5>
        <input
          type="password"
          name="password"
          value={this.state.password}
          onChange={this.onChange}
        />

        <button onClick={this.submit}>Submit</button>
      </div>
    );
  }
}

export default connect(null, { signIn })(SignIn);
