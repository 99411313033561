import { SUBSCRIPTIONS_GET, CLEAR_SUBSCRIPTIONS, RESET_STORE } from '../actions/types';

const INTIAL_STATE = {
  subscriptions: [],
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SUBSCRIPTIONS_GET:
      return {
        ...state,
        subscriptions: [...state.subscriptions, ...action.payload]
      };
    case CLEAR_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: []
      };
    case RESET_STORE:
      return {
        ...INTIAL_STATE,
      }
    default:
      return state;
  }
};