import React, { useEffect, useState, useRef } from "react";
import { HashRouter as Router, Route, Routes, Redirect } from "react-router-dom";
import API from './utils/api';
// const BrowserHistory = require('react-router/lib/BrowserHistory').default;
// import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import { isSignedIn, isLoggedIn, setUserName, signOut } from "./actions";
// import { Auth, Hub } from 'aws-amplify';
// import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import Header from "./components/header/header";
import Home from "./pages/home";
import Trending from "./pages/trending";
import Subscriptions from "./pages/subscriptions";
import Saved from "./pages/saved";
import Account from "./pages/account";
import About from './pages/About';
import WritersMode from "./pages/WritersMode";
import ReaderMode from "./pages/ReaderMode";
import PreviewMode from "./pages/PreviewMode";
import NewPiece from "./pages/NewPiece";
import Footer from "./components/footer/footer";
import SignUp from "./pages/signUp";
import SignIn from "./pages/signIn";
import AuthModal from "./components/authModal";
import AuthorPage from "./pages/AuthorPage";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
//TODO: Delete useBottomScrollListener
// import { useBottomScrollListener } from "react-bottom-scroll-listener";
import useScrollTrigger from '@mui/material/useScrollTrigger';
// const customHistory = createBrowserHistory();

const App = (props) => {

  const shouldMount = useRef(true);
  // const scrollRef = useBottomScrollListener(() => handleEndOfPage());
  const [scrollTarget, setScrollTarget] = useState(undefined)
  const scrollTrigger = useScrollTrigger({ target: scrollTarget });
  // const [isLoggedIn, setIsLoggedIn] = useState(props.isLoggedIn);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {

    // return unsubscribe;
    let token = localStorage.getItem("accessToken");
    // // console.log('token from app.js: ', token);
    // setLoader(true);

    const fetchData = async () => {
      await API.checkToken(token)
        .then(data => data.json())
        .then(response => {
          console.log('response from App.js: ', response);
          props.isLoggedIn(true);
          props.isSignedIn(true);
          // write isLoggedIn bool to local storage
          localStorage.setItem("isLoggedIn", "true");
        });
    }

    fetchData()
      .then(x => console.log('Login Succesfull'))
      .catch(error => {
        token && props.signOut(); //TODO: add redirect using useNavigate, add .then to action call
        // setIsLoggedIn(false);
        props.isSignedIn(false);
        console.log('error from App.js: ', error);
      });

    // API.checkToken(token)
    //   .then(data => data.json())
    //   .then(response => {
    //     console.log('response from App.js: ', response);
    //     props.isSignedIn(true);
    //   })
    //   .catch(error => {
    //     // setAuthModal(true)
    //     token && props.signOut();
    //     props.isSignedIn(false);
    //     console.log('error from App.js: ', error);
    //   });
    // }
  }, []);

  // const handleEndOfPage = () => {
  //   console.log('inside handleEndOfPage from app.js');
  //   // TODO: show loading spinner and make fetch request to api
  //   // page = page + 1;
  //   // props.getRecommended();
  //   // setPage(page); // when setPage finishes turn off spinner/loader
  // }

  // async componentDidMount() {
  //   // ? Put loader here to allow for time for logging in so we wont get the flash of auth modal.
  //   // put check if token is there write to store that isSignIn is true?
  //   // write getAuth lamda?
  //   let token = localStorage.getItem("accessToken");
  //   // console.log('token from app.js: ', token);
  //   // setLoader(true);
  //   API.checkToken(token)
  //     .then(data => data.json())
  //     .then(response => {
  //       console.log(response);
  //       props.isSignedIn(true);
  //     })
  //     .catch(error => {
  //       setAuthModal(true)
  //       props.signOut();
  //       props.isSignedIn(false);
  //       console.log(error);
  //     });
  //   // const response = API.getAuth({
  //   //   headers: {
  //   //     authorization: `Bearer ${token}`
  //   //   }
  //   // });
  //   // console.log('response from app.js: ', response);
  // }

  return (
    //FIXME: wire up back button in the pause menu to be like the back button in the browser
    <Router>
      {/* {props.showMainHeader && <Header scrollTrigger={scrollTrigger}/>} - use when you want to specify a different div for auto hide mui nav */}
      {props.showMainHeader && <Header />}
      {/* TODO: bring this to pause menu to trigger auth modal, also is this doing anything?? */}
      {props.showModal && <AuthModal />}
      {/* <div style={{ marginBottom: 70 }}></div> */}
      {/* //? DELETE? <div id="routerContent" className="d-flex flex-fill flex-column"></div> */}
      {/* FIXME: Rename to something more accurate (ie cardContainer) */}
      {/* <div ref={scrollRef} id="footerContainer"> */}
      {/* <div ref={node => { if (node) setScrollTarget(node) }} id="footerContainer"> - use when you want to specify a different div for auto hide mui nav*/}
      <div id="footerContainer">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/signIn" exact element={<AuthModal />} />
          <Route path="/trending" element={<Trending />} />
          <Route path="/about" exact element={<About />} />
          {/* FIXME: implement this on tap */}
          {/* //!Refreshing of readermode - 404 - server side rendering? */}
          <Route path="/piece/:id" element={<ReaderMode />} />
          <Route path="/authorPage/:id" element={<AuthorPage />} />
          <Route path="/preview" element={<PreviewMode />} />

          {/* <ProtectedRoute path="/subscriptions" element={<Subscriptions />} />
            <ProtectedRoute
              path="/saved"
              isSignedIn={props.isSignedIn}
              element={<Saved />}
            /> */}
          {/* <Route
            path="/account"
            element={
              //! Redux not be causing rerender for Routes
              <ProtectedRoute path="/account">
                <Account />
              </ProtectedRoute>
            }
          /> */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/account" element={<Account />} />
            <Route path="/writersMode" element={<WritersMode />} />
            <Route path="/newPiece" element={<NewPiece />} />
            <Route path="/newPiece/:id" element={<NewPiece />} />
          </Route>
          {/* <ProtectedRoute
              path="/writersMode"
              isSignedIn={props.isSignedIn}
              element={<WritersMode />}
            />
            <ProtectedRoute
              exact
              path="/newPiece"
              isSignedIn={props.isSignedIn}
              element={<NewPiece />}
            />
            <ProtectedRoute
              exact
              path="/newPiece/:id" // TODO: rename to editPiece
              isSignedIn={props.isSignedIn}
              element={<NewPiece />}
            /> */}

          {/* <Route path="/signup" render={props => <SignUp />} />
          <Route path="/signin" render={props => <SignIn />} /> */}
          {/* <Loader /> */}
        </Routes>
      </div>
      {props.showFooter ? <Footer URL={window.location.pathname} /> : <></>}
    </Router>
  );
}

// const ProtectedRoute = ({ element: Comp, isSignedIn, path, ...rest }) => {
//   return (
//     <Route
//       path={path}
//       {...rest}
//       render={props => {
//         return isSignedIn ? (
//           <Comp {...props} />
//         ) : (
//           <>
//             <AuthModal destination={Comp} />
//             {/* {showAuthModal && <AuthModal destination={Comp} />} */}
//           </>
//         );
//       }}
//     />
//   );
// };

const mapStateToProps = state => {
  console.log('auth state from app.js: ', state.auth);
  return {
    showModal: state.auth.showModal,
    loggedIn: state.auth.isSignedIn,
    showMainHeader: state.menus.mainHeader,
    showFooter: state.menus.footer,
    mainHeaderChips: state.menus.mainHeaderChips,
  };
};

export default connect(mapStateToProps, { isSignedIn, isLoggedIn, setUserName, signOut })(App);
