import { SET_POP_OVER, RESET_STORE } from '../actions/types';

const INTIAL_STATE = {
  isPopOverOpen: false,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SET_POP_OVER:
      return { ...state, isPopOverOpen: action.payload };
    case RESET_STORE:
      return {
        ...INTIAL_STATE,
      }
    default:
      return state;
  }
};
