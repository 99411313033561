import { UP_VOTE, DOWN_VOTE, CURRENT_VOTE, HAS_VOTE, UPDATE_UP_VOTE, UPDATE_DOWN_VOTE, RESET_STORE } from '../actions/types';
import { hasVote } from '../actions/vote';

// TODO: Fix type on all reducers
const INTIAL_STATE = {
  hasVote: null,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case UP_VOTE:
      return { ...state, hasVote: { upVote: true, downVote: false } };
    case DOWN_VOTE:
      return { ...state, hasVote: { upVote: false, downVote: true } };
    case UPDATE_UP_VOTE:
      return { ...state, hasVote: { upVote: false, downVote: false } };
    case UPDATE_DOWN_VOTE:
      return { ...state, hasVote: { upVote: false, downVote: false } };
    case HAS_VOTE:
      return { ...state, hasVote: { ...action.payload } };
    case RESET_STORE:
      return {
        ...INTIAL_STATE,
      }
    default:
      return state;
  }
};
