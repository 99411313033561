import React, { Component } from "react";
import { connect } from "react-redux";
import {
  authorSettings,
  deletePieceModal,
  revertPieceToDraft,
  clearEditor,
} from "../../actions";
import { pauseMenu } from "../../actions/menus";
import styles from "./index.module.scss";

export class AuthorSettingsModal extends Component {
  state = {};

  //for a more complex close modal function, can put logic here
  // close = () => this.props.closeModal;

  showDelete = () => {
    this.props.deletePieceModal();
    this.props.authorSettings();
  };
  showRevert = () => {
    this.props.revertPieceToDraft();
    this.props.authorSettings();
  };

  render() {
    console.log(this.state);

    if (this.props.isSignedIn) {
      return <this.props.destination />;
    } else {
      return (
        <div className={styles.bgAuthorSettingsModal}>
          <div className={styles.authorSettingsModalContents}>
            <h4>
              Author Settings <br /> for this piece:
            </h4>
            <input
              onClick={this.showDelete}
              className={styles.authorSettingsModalButton}
              value="Delete Piece"
            />
            <input
              onClick={this.showRevert}
              className={styles.authorSettingsModalButton}
              value="Revert To Draft"
            />
            <div className={styles.closeAuthorSettingsModalLink}>
              <a href="#" onClick={this.props.authorSettings}>
                close
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ pieceId: state.pieces.currentPiece._id });

export default connect(mapStateToProps, {
  authorSettings,
  pauseMenu,
  deletePieceModal,
  revertPieceToDraft,
  clearEditor,
})(AuthorSettingsModal);
