import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { clearSaved, clearSubscriptions, hideFooter, showFooter, showModal, updateButtonClickedAuth, subscribe, unSubscribe, UnSubscribeModal } from "../actions";
import { handleVote } from "../actions/vote";
import { pauseMenu, handleSaveUI } from "../actions/menus";
import PauseMenuHeader from "./../components/PauseMenuHeader/PauseMenuHeader";
import DeleteDraftModal from "./../components/DeleteDraftModal";
import UnSubscribeConfirmationModal from "./../components/UnSubscribeConfirmationModal/UnSubscribeConfirmationModal";
import PublishModal from "./../components/PublishModal";
import TextEditor from "../components/TextEditor/TextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCheck,
  faComments,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import API from "./../utils/api";

import styles from "./PauseMenu.module.scss";

class PauseMenu extends Component {
  componentDidMount() {
    this.props.hideFooter();
  }

  handleSave = () => {
    API.favorite(this.props.currentPiece).then(({ data }) => {
      this.props.handleSaveUI(data.hasSaved);
      this.props.clearSaved();
      console.log("DATA from handleSave(): ", data);
    });
  };

  handleSubscribe = () => {
    if (this.props.isSubscribed) {
      this.props.UnSubscribeModal();
    } else {
      this.props.subscribe(this.props.authorId);
    }
    this.props.clearSubscriptions();
  };

  handleAuth = (buttonClicked) => {
    // Show the authentication modal
    this.props.showModal();
    // update store
    this.props.updateButtonClickedAuth(buttonClicked);
  };

  showPauseMenu = (e) => {
    let isDisabled = e.target.getAttribute("data-disablePauseMenu");
    let tagName = e.target.tagName;
    if (tagName === "path" || tagName === "svg" || tagName === "BUTTON") {
      tagName = false;
    }
    if (isDisabled == false || tagName) {
      this.props.pauseMenu();
    }
  };

  render() {
    return (
      <>
        <PauseMenuHeader />
        {this.props.showUnSubscribeModal && (
          <UnSubscribeConfirmationModal authorId={this.props.authorId} />
        )}
        <div className={styles.pauseMenuContainer} onClick={this.showPauseMenu}>
          <div className={styles.pauseMenuContents}>
            <div className={styles.pageNumberContainer}>
              <h3>Page:</h3>
              <h2>#/#</h2>
            </div>
            <div className={styles.authorIconsContainer}>
              <div className={styles.seeMoreContainer}>
                {this.props.userId == this.props.authorId ? (
                  <p>This is your piece</p>
                ) : (
                  <>
                    <span>See more from </span>
                    <Link
                      className={styles.pauseMenuAuthorLink}
                      to={`/authorPage/${this.props.currentPiece.authorName._id}`}
                    >
                      {this.props.currentPiece.authorName.username}
                    </Link>
                  </>
                )}
              </div>
              {this.props.userId != this.props.authorId && (
                <div id={styles.subButtonContainer}>
                  <button
                    data-disablePauseMenu={true}
                    id={styles.subButton}
                    style={{
                      backgroundColor: this.props.isSubscribed ? "gray" : "red",
                      color: "white",
                    }}
                    onClick={
                      this.props.isSignedIn
                        ? this.handleSubscribe
                        : () => this.handleAuth("subscribe")
                    }
                  >
                    {this.props.isSubscribed ? "Subscribed" : "Subscribe"}
                  </button>
                </div>
              )}
              <div className={styles.pauseMenuIconsContainer}>
                {/* TODO: set backgroundColor using RGB, instead of opacity (background-color: rgba(255,255,255,.8);) */}
                {/* TODO: Figure out how to dismiss the pause menu only when clicking the overlay in a better way. */}
                <span
                  data-disablePauseMenu={true}
                  className="fa-layers fa-fw"
                  onClick={
                    this.props.isSignedIn
                      ? this.handleSave
                      : () => this.handleAuth("saved")
                  }
                >
                  <FontAwesomeIcon
                    data-disablePauseMenu={true}
                    className={styles.pauseMenuCircle}
                    icon={faCircle}
                    size="lg"
                    color={this.props.hasSaved ? "#484848" : "#222222"}
                  />
                  <FontAwesomeIcon
                    data-disablePauseMenu={true}
                    className={styles.pauseMenuSaved}
                    icon={faCheck}
                    color="white"
                    size="sm"
                  />
                </span>
                <span data-disablePauseMenu={true} className="fa-layers fa-fw">
                  <FontAwesomeIcon
                    data-disablePauseMenu={true}
                    className={styles.pauseMenuCircle}
                    icon={faCircle}
                    size="lg"
                  />
                  <FontAwesomeIcon
                    data-disablePauseMenu={true}
                    className={styles.pauseMenuComments}
                    icon={faComments}
                    color="white"
                    size="sm"
                  />
                </span>
                <span
                  data-disablePauseMenu={true}
                  className="fa-layers fa-fw"
                  onClick={
                    this.props.isSignedIn
                      ? () => this.props.handleVote("upVote")
                      : () => this.handleAuth("upVote")
                  }
                >
                  <FontAwesomeIcon
                    data-disablePauseMenu={true}
                    className={styles.pauseMenuCircle}
                    icon={faCircle}
                    size="lg"
                    color={this.props.hasVote.upVote ? "#484848" : "#222222"}
                  />
                  <FontAwesomeIcon
                    data-disablePauseMenu={true}
                    className={styles.pauseMenuUpvote}
                    icon={faChevronUp}
                    color="white"
                    size="sm"
                  />
                </span>
                <span
                  data-disablePauseMenu={true}
                  className="fa-layers fa-fw"
                  onClick={
                    this.props.isSignedIn
                      ? () => this.props.handleVote("downVote")
                      : () => this.handleAuth("downVote")
                  }
                >
                  <FontAwesomeIcon
                    data-disablePauseMenu={true}
                    className={styles.pauseMenuCircle}
                    icon={faCircle}
                    size="lg"
                    color={this.props.hasVote.downVote ? "#484848" : "#222222"}
                  />
                  <FontAwesomeIcon
                    data-disablePauseMenu={true}
                    className={styles.pauseMenuDownVote}
                    icon={faChevronDown}
                    color="white"
                    size="sm"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    isSubscribed: state.auth.isSubscribed,
    hasSaved: state.menus.hasSaved,
    hasVote: state.vote.hasVote,
    upVote: state.vote.upVote,
    downVote: state.vote.downVote,
    currentPiece: state.pieces.currentPiece,
    showUnSubscribeModal: state.pieces.showUnSubscribeModal,
    userId: state.auth.id,
    // TODO: Remove authorId from currentPiece - no longer needed?
    authorId: state.author.id,
  };
};
export default connect(mapStateToProps, {
  clearSaved,
  clearSubscriptions,
  hideFooter,
  showFooter,
  pauseMenu,
  showModal,
  handleVote,
  updateButtonClickedAuth,
  handleSaveUI,
  subscribe,
  unSubscribe,
  UnSubscribeModal,
})(PauseMenu);
