import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "../../piece/Card/Card";
import { Link } from "react-router-dom";
import { getPromoted } from "../../../actions";

import styles from "../readersMode.module.scss";

//TODO: DELETE This
class Promoted extends Component {
  // componentDidMount() {
  //   this.props.getPromoted();
  // }
  render() {
    return (
      <div>
        {/* TODO - Need to make this title a component with it self-contained styled to be more dry */}
        {this.props.promoted.map((piece) =>
          piece.published ? (
            <Link className="readersModeLink" to={`/piece/${piece._id}`}>
              <Card title={piece.title} />
            </Link>
          ) : (
            ""
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ promoted: state.pieces.promoted });

export default connect(mapStateToProps, { getPromoted })(Promoted);
