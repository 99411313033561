import { DISPLAY_MAIN_HEADER, DISPLAY_MAIN_HEADER_CHIPS, DISPLAY_FOOTER, SHOW_PAUSE_MENU, SHOW_PAUSE_MENU_SUBSCRIBE_BUTTON, SHOW_PREVIEW_PAUSE_MENU, HAS_SAVED, RESET_STORE } from '../actions/types';

const INTIAL_STATE = {
  mainHeader: true,
  mainHeaderChips: false,
  footer: true,
  pauseMenu: false,
  previewPauseMenu: false,
  hasSaved: false,
  showPauseMenuSubscribeButton: false,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case DISPLAY_MAIN_HEADER:
      return { ...state, mainHeader: action.payload.mainHeader };
    case DISPLAY_MAIN_HEADER_CHIPS:
      return { ...state, mainHeaderChips: action.payload.mainHeaderChips };
    case DISPLAY_FOOTER:
      return { ...state, footer: action.payload.footer };
    case SHOW_PAUSE_MENU:
      return { ...state, pauseMenu: !state.pauseMenu };
    case SHOW_PREVIEW_PAUSE_MENU:
      return { ...state, previewPauseMenu: !state.previewPauseMenu };
    case SHOW_PAUSE_MENU_SUBSCRIBE_BUTTON:
      return { ...state, showPauseMenuSubscribeButton: !state.showPauseMenuSubscribeButton };
    case HAS_SAVED:
      return { ...state, hasSaved: action.payload };
    case RESET_STORE:
      return {
        ...INTIAL_STATE,
      }
    default:
      return state;
  }
};
