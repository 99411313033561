import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { hideMainHeader, showMainHeader, setPopOver } from "../actions";
import NewPieceHeader from "./../components/NewPieceHeader/NewPieceHeader";
import DeleteDraftModal from "./../components/DeleteDraftModal";
import DuplicatePublishModal from "./../components/duplicatePublishModal";
import PublishModal from "./../components/PublishModal";

import TextEditor from "../components/TextEditor/TextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import styles from "./NewPiece.module.scss";

class NewPiece extends Component {
  componentDidMount() {
    this.props.hideMainHeader();
  }

  componentWillUnmount() {
    this.props.showMainHeader();
  }

  redirect = () => {
    console.log('NewPiece Redirect called!');
    //!Fix to work with HasshRouter
    this.props.history.push("/writersMode");
  };

  handlePopOver = () => {
    this.props.isPopOverOpen && this.props.setPopOver(false);
  };

  render() {
    return (
      <>
        {/* Unused style for newPieceContainer */}
        <div className={styles.newPieceContainer} onClick={this.handlePopOver}>
          {/* <Link className="icon noSelect backToAccount" to="/writersMode">
                      <FontAwesomeIcon icon={faArrowLeft} />
                  </Link> */}
          <NewPieceHeader />

          <div className={styles.textEditor}>
            <TextEditor />
          </div>
          {/* FIXME: move duplicate modal inside TextEditor*/}
          {this.props.isPublishedDuplicate ? (
            <DuplicatePublishModal redirect={this.redirect} />
          ) : null}
          {this.props.showPublishedConfirmation ? (
            <PublishModal redirect={this.redirect} />
          ) : null}
          {this.props.showDeleteDraftConfirmation ? (
            <DeleteDraftModal redirect={this.redirect} />
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isPublishedDuplicate: state.pieces.isPublishedDuplicate,
  showPublishedConfirmation: state.pieces.showPublishedConfirmation,
  showDeleteDraftConfirmation: state.pieces.showDeleteDraftConfirmation,
  isPopOverOpen: state.writersMode.isPopOverOpen,
});

export default connect(mapStateToProps, {
  hideMainHeader,
  showMainHeader,
  setPopOver,
})(NewPiece);
