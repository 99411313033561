import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "../../piece/Card/Card";
import { Link } from "react-router-dom";
import { getDrafts, createEditor } from "../../../actions";
import styles from "./UserDrafts.module.scss";

// TODO: convert to function component
class UserDrafts extends Component {
  componentDidMount() {
    let token = localStorage.getItem(`accessToken`);
    this.props.getDrafts(token);
  }
  render() {
    return (
      <>
        {console.log("in user drafts: ", this.props.drafts)}
        {console.log("in user drafts: ", this.props)}
        {/* TODO - Some sort of loop will go here to iterate over the relevant pieces from the db */}
        <h4 className="sectionHeaders">Your Drafts</h4>
        {this.props.drafts.map((draft) =>
        (
          // FIXME: send id instead of draft to later call the db
          <Link
            key={draft.pieceId}
            className={styles.writersModeLink}
            to={`/newPiece/${draft.pieceId}`}
          >
            {console.log(draft)}
            <Card
              title={draft.title}
              created={draft.created}
              draftPrefix={"Edited "}
              pageCount={draft.pageCount}
            />
          </Link>
        )
        )}
        <div className={styles.loadMorePiecesContainer}>
          <Link className={styles.loadMorePiecesLink} to="#">
            Load More
          </Link>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ drafts: state.pieces.drafts });

export default connect(mapStateToProps, { getDrafts, createEditor })(
  UserDrafts
);
