import React, { Component } from "react";
import { connect } from "react-redux";
import {
  closeModal,
  clearEditor,
  unSubscribe,
  UnSubscribeModal,
} from "../../actions";

import styles from "./UnSubscribeConfirmationModal.module.scss";

export class UnSubscribeConfirmationModal extends Component {
  state = {};

  //for a more complex close modal function, can put logic here

  handleUnSubscribe = () => {
    this.props.unSubscribe(this.props.authorId);
    this.props.UnSubscribeModal();
  };

  render() {
    console.log(this.state);

    return (
      <div className={styles.bgModal}>
        <div className={styles.modalContents}>
          <h4>Are you sure you want to unsubscribe?</h4>
          {/* //TODO: Rename classes */}
          <input
            onClick={this.handleUnSubscribe}
            className={styles.publishButton}
            value="Unsubscribe!"
          />
          <div className={styles.closeDuplicateModal}>
            <a href="#" onClick={this.props.UnSubscribeModal}>
              Nevermind, take me back
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  clearEditor,
  closeModal,
  unSubscribe,
  UnSubscribeModal,
})(UnSubscribeConfirmationModal);
