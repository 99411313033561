import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Popover from "react-tiny-popover";
import { publishConfirmation, deleteDraftConfirmation } from "../../actions";
import { pauseMenu } from "../../actions/menus";
import { clearEditor } from "../../actions";

import styles from "./PauseMenuHeader.module.scss";

class PauseMenuHeader extends Component {
  handlePauseMenuLeave = () => {
    this.props.pauseMenu();
    window.history.back();
  };

  render() {
    return (
      <div className={styles.navbar} id={styles.pauseMenuHeader}>
        <div className={styles.pauseMenuHeaderContentContainer}>
          <a
            className={classnames(
              styles.noSelect,
              styles.backToAccount,
              styles.pauseMenuHeaderIcon
            )}
            onClick={this.handlePauseMenuLeave}
          >
            <FontAwesomeIcon
              className={styles.arrowLeftIcon}
              icon={faArrowLeft}
            />
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveStatus: state.textEditor.status,
});

export default connect(mapStateToProps, { pauseMenu, clearEditor })(
  PauseMenuHeader
);
