import React, { Component } from 'react'
import { connect } from 'react-redux';
import { signUp } from '../actions';
// TODO: DELETE THIS COMPONENT

class SignUp extends Component {
  state = {
    email: '',
    username: '',
    password: ''
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value })
  submit = () => {
    this.props.signUp(this.state);
  }

  render() {
    return (
      <div>
        <h3>SignUp</h3>
        <h5>Email</h5>
        <input type='text' name='email' value={this.state.email} onChange={this.onChange} />
        <h5>Name</h5>
        <input type='text' name='username' maxlength="20" value={this.state.username} onChange={this.onChange} />
        <h5>Password</h5>
        <input type='password' name='password' value={this.state.password} onChange={this.onChange} />

        <button onClick={this.submit}>Submit</button>
      </div>
    )
  }
}

export default connect(null, { signUp })(SignUp);