import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "react-tiny-popover";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import {
  savePieceLeave,
  publishConfirmation,
  deleteDraftConfirmation,
  showFooter,
  setPopOver,
} from "../../actions";

import styles from "./NewPieceHeader.module.scss";

class NewPieceHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
    };
  }

  componentWillUnmount() {
    this.props.showFooter();
  }

  handlePopOver = () => {
    this.props.setPopOver(!this.props.isPopOverOpen);
  };

  handlePublish = () => {
    this.props.publishConfirmation();
    this.props.setPopOver(false);
  };

  handleDelete = () => {
    this.props.deleteDraftConfirmation();
    this.props.setPopOver(false);
  };

  render() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          className={classnames(styles.navbar, {
            [styles.navbarHidden]: !this.state.visible,
          })}
          id={styles.newPieceHeader}
        >
          <div className={styles.newPieceHeaderContentContainer}>
            <Link
              className={classnames(
                styles.noSelect,
                styles.backToAccount,
                styles.newPieceHeaderIcon
              )}
              to="/writersMode"
              // FIXME: This action doesnt update anything - no dispatch
              onClick={this.props.savePieceLeave}
            >
              <FontAwesomeIcon
                className={styles.arrowLeftIcon}
                icon={faArrowLeft}
              />
            </Link >
            <div className={styles.newPieceHeaderRightDiv}>
              <p className={styles.pageCount}>1/1</p>
              {this.props.saveStatus}
              <Popover
                containerClassName={"shadow"}
                isOpen={this.props.isPopOverOpen}
                position={"bottom"} // preferred position
                padding={25}
                content={
                  <div className={styles.popOverContent}>
                    {/* //NOTE: Publish button to publish the piece */}
                    {/* <Link className="newPieceLink" to="/newPiece"> */}
                    <li
                      className={styles.newPieceLink}
                      onClick={this.handlePublish}
                    >
                      Publish
                    </li>
                    {/* </Link> */}
                    <Link className={styles.newPieceLink} to="/preview">
                      <li>Preview</li>
                    </Link>
                    <li
                      className={styles.newPieceLink}
                      onClick={this.handleDelete}
                    >
                      Delete
                    </li>
                  </div>
                }
              >
                <FontAwesomeIcon
                  //TODO: - REPLACE ICON WITH SVG COMPONENT (DOESNT EXIST YET)
                  className={styles.ellipsisMenuIcon}
                  color={"black"}
                  icon={faEllipsisV}
                  onClick={this.handlePopOver}
                />
              </Popover>
            </div>
          </div >
        </AppBar >
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  saveStatus: state.textEditor.status,
  isPopOverOpen: state.writersMode.isPopOverOpen,
});

export default connect(mapStateToProps, {
  savePieceLeave,
  publishConfirmation,
  deleteDraftConfirmation,
  showFooter,
  setPopOver,
})(NewPieceHeader);
