import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSaved } from "../actions";
import Card from "../components/piece/Card/Card";
import { Link } from "react-router-dom";
import useWindowScrollPosition from "./../hooks/useWindowScrollPosition";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

// This stylesheet is empty. Convert to module later.
import styles from "./saved.module.scss";

// TODO: move to feature
const Saved = (props) => {
  // const [saved, setSaved] = useState([]);
  let [page, setPage] = useState(1);

  const scrollRef = useBottomScrollListener(() => handleEndOfPage());
  useWindowScrollPosition("Saved_ScrollTop", true, scrollRef);

  useEffect(() => {
    if (props.saved.length == 0) { //saved empty
      localStorage.setItem("Saved_ScrollTop", 0);
      console.log(
        "The saved array exists (INSIDE IF): ",
        props.saved.length == 0
      );
      props.getSaved(page);
    }
  }, []);
  // TODO: move to utils
  const handleEndOfPage = () => {
    // TODO: show loading spinner and make fetch request to api
    page = page + 1;
    props.getSaved(page);
    setPage(page); // when setPage finishes turn off spinner/loader
  };

  return (
    <>
      {/* // ? Make new component to combine with subscriptions page? */}
      <h4 className="sectionHeaders">Saved</h4>
      {
        props.saved.length > 0 ?
          <div ref={scrollRef} className={styles.savedTab}>

            {props.saved.map((saved) => (
              saved.pieceId ? (
                <Link className="readersModeLink" to={`/piece/${saved.pieceId}`}>
                  <Card
                    title={saved.title}
                    author={saved.author} //! does pass currently logged in user's name as well?
                    created={saved.created}
                  />
                </Link>
              ) : (
                <Card title="Piece Deleted" created={Date.now()} />
              )
            ))}
          </div> :
          <div className={`container ${styles.noSavedontainer}`}>
            <h4>You do not have any saved pieces!</h4>
            <p className={styles.noSavedText}>
              Once you save a piece it will show up here.
            </p>
            <button
              style={{ backgroundColor: "red", color: "white" }}
              id={styles.emptySaveButton}
            // onClick={}
            >
              Search popular pieces
            </button>
          </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    saved: state.saved.saved,
  };
};

export default connect(mapStateToProps, { getSaved })(Saved);
