import React, { useEffect, useState } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  subscribe,
  unSubscribe,
  showModal,
  updateButtonClickedAuth,
  setSubscribeUI,
  setUnSubscribeUI,
  UnSubscribeModal,
} from "./../actions/index";
import { setAuthor } from "./../actions/author";
import API from "./../utils/api";

import Collections from "../components/profile/Collections/Collections";
import UserInfo from "../components/profile/userInfo/userInfo";
import UnSubscribeConfirmationModal from "../components/UnSubscribeConfirmationModal/UnSubscribeConfirmationModal";

import styles from "./account.module.scss"; //TODO: Create scss for author page

function AuthorPage(props) {
  const params = useParams();
  const redirect = () => {
    //!Fix to work with HasshRouter - use useNavigate
    props.history.push("/account");
    // return <Redirect to='/account' />
  };

  useEffect(() => {
    console.log(props.userId);
    console.log(params.id);

    if (props.userId == params.id) {
      console.log('user id is equal to params id');
      redirect();
    }

    props.setAuthor({id: params.id});
    console.log('props.isLoggedIn', props.isLoggedIn);
    console.log('props', props);
    if (props.isSignedIn) {
      API.isSubscribed(params.id).then(({ data }) => {
        console.log("isSubscribed from AuthorPage: ", data);
        if (data) {
          props.setSubscribeUI();
        } else {
          props.setUnSubscribeUI();
        }
      });
    };
  }, [props.isSignedIn]);

  const handleAuth = (buttonClicked) => {
    // Show the authentication modal
    props.showModal();
    // update store
    props.updateButtonClickedAuth(buttonClicked);
  };

  return (
    <>
      {props.showUnSubscribeModal && (
        <UnSubscribeConfirmationModal authorId={params.id} />
      )}
      {/* Pass a prop so UserInfo can determine what button action to execute */}
      <UserInfo
        buttonName={props.isSubscribed ? "Subscribed" : "Subscribe"}
        backgroundColor={props.isSubscribed ? "gray" : "red"}
        action={
          props.isSignedIn
            ? !props.isSubscribed
              ? () => props.subscribe(props.author.id)
              : () => props.UnSubscribeModal()
            : () => handleAuth("subscribe")
        }
        authorId={params.id}
      />
      {/* //TODO: Fix positioning of collections */}
      <div id={styles.collectionsDiv}> 
        <h1 className={styles.sectionTitles}>Collections</h1>
        <div className={styles.collectionsContainer}>
          <Collections />
          <Collections />
          <Collections />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.auth.accessToken,
  author: state.author,
  isSignedIn: state.auth.isSignedIn,
  // TODO: Move modal bool to its own reducer
  showUnSubscribeModal: state.pieces.showUnSubscribeModal,
  isSubscribed: state.auth.isSubscribed,
  userId: state.auth.id,
});

export default connect(mapStateToProps, {
  subscribe,
  setAuthor,
  unSubscribe,
  showModal,
  updateButtonClickedAuth,
  setSubscribeUI,
  setUnSubscribeUI,
  UnSubscribeModal,
})(AuthorPage);
