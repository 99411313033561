import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import styles from "./About.module.scss";

const About = () => {
  return (
    <>
      <div className={styles.aboutContainer}>
        <div className={styles.aboutContent}>
          <h1 className={styles.aboutTitle}>About</h1>
          <p>Writ.ink is the app for writers.</p>
          <p>
            Make and present your work to the community. Discover other writers
            and subscribe to add them to your feed. Enjoy reading long-form
            content with a reading experience designed for the modern web.
          </p>
          <p>
            By focusing on text, Writ.ink allows you to slow down and focus on
            the words that often get drowned out elsewhere on the internet.
            Write it on Writ.ink today!
          </p>
        </div>
        <hr />
        <p>Contact</p>
        <p>Press</p>
        <p>© 2019 Writ.ink, LLC</p>
      </div>
    </>
  );
}

export default About;
