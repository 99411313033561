import { SET_AUTHOR, RESET_STORE } from '../actions/types';

const INTIAL_STATE = {
  id: null,
  pieceCount: null,
  subscriberCount: null,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTHOR:
      console.log('payload from author reducer ', action.payload);
      return { ...state, ...action.payload };
    case RESET_STORE:
      return {
        ...INTIAL_STATE,
      }
    default:
      return state;
  }
};
