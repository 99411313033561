import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { Animated } from "react-animated-css";
import { debounce } from "lodash";
import ReaderModeInfo from "../ReaderModeInfo/ReaderModeInfo";

import styles from "./ReaderModeContent.module.scss";

function ReaderModeContent({ editorState, location }) {
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");
  const shouldMount = useRef(true);

  useEffect(() => {
    if (shouldMount.current) {
      console.log(page);
      if (page) {
        document.getElementById(page).scrollIntoView();
      }
    }
  });

  return (
    <>
      <div className={styles.readersModeContent}>
        {/* <ReaderModeInfo pageCount={editorState.length} /> */}
        {editorState.map((page, index) => (
          <div>
            {index > 0 && (
              <>
                <hr />
                <h3 id={index + 1} className={styles.pageNumber}>{`Page ${index + 1}/${editorState.length
                  }`}</h3>
              </>
            )}
            <p>{page}</p>
          </div>
        ))}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    pieces: state.pieces,
    userId: state.auth.id,
    authorId: state.author.id,
  };
};

export default connect(mapStateToProps, {})(ReaderModeContent);
