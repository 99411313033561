import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getRecommended, showMainHeaderChips } from "../actions";
import Promoted from "../components/features/promoted/promoted";
import Recommended from "../components/features/recommended/recommended";
import useWindowScrollPosition from "./../hooks/useWindowScrollPosition";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import styles from "./Home.module.scss";

// Bring in an action to be used
const Home = (props) => {

  // let [page, setPage] = useState(1);

  const scrollRef = useBottomScrollListener(() => handleEndOfPage());
  useWindowScrollPosition('Home_ScrollTop', true, scrollRef)
  // Adds an event listener when the component is mount.
  useEffect(() => {
    console.log('The recommended array exists: ', props.recommended.Items.length == 0);
    props.showMainHeaderChips(true);
    if (props.recommended.Items.length == 0) { //recommended empty 
      localStorage.setItem('Home_ScrollTop', 0);
      console.log('The recommended array exists (INSIDE IF): ', props.recommended.Items.length == 0);
      props.getRecommended();
    }
    return () => {
      props.showMainHeaderChips(false);
    };
  }, []) //TODO: fix this useEffect for React 18 remount
  const handleEndOfPage = () => {
    console.log('inside handleEndOfPage from home.js');
    // TODO: show loading spinner and make fetch request to api
    // page = page + 1;
    props.getRecommended();
    // setPage(page); // when setPage finishes turn off spinner/loader
  }


  return (
    <>
      {/* //TODO: Fix save scroll position feature */}
      {/* TODO: //! Use different infinite scroll package so we can use the ref for setScrollTrigger from MUI useScrollTrigger target option */}
      <div ref={scrollRef} className={styles.homeTab}>
        {/* //TODO: Remove promoted component and files */}
        {/* <Promoted />  */}
        <Recommended />
      </div>
    </>
  );
}

const mapStateToProps = state => {
  console.log(state);
  return {
    recommended: state.pieces.recommended,
  }
}
export default connect(mapStateToProps, { getRecommended, showMainHeaderChips })(Home);
