// control/separate all our API calls 
import axios from 'axios';
axios.defaults.baseURL = 'https://1iznpumm31.execute-api.us-east-1.amazonaws.com/dev';
const baseURL = `https://1iznpumm31.execute-api.us-east-1.amazonaws.com/dev`
//TODO: convert axios to fetch
// const token = localStorage.getItem('accessToken');

const API = {
    // Voting
    unVote: (unVote, pieceId) => axios.put('/unVote', { unVote, pieceId }),
    vote: (vote, pieceId) => axios.put('/vote', { vote, pieceId }),
    getVote: (id) => axios.get(`/getVote/${id}`),
    // Saving (Pause Menu)
    favorite: ({ _id, created, authorName: { username }, title }) => axios.put('/favorite', { pieceId: _id, created, username, title }),
    // Piece
    // updatePiece: (pieceId, data) => axios.put(`/piece/${pieceId}`, data), // auto save
    // newDraft: (axiosData) => axios.post("/piece", axiosData),
    updatePiece: (pieceId, draftData, SK) => fetch(`${baseURL}/updateDraft`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify({ pieceId, draftData, SK })
    }),
    newDraft: (daftData) => fetch(`${baseURL}/newDraft`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify(daftData)
    }),
    revertPublishedPiece: (pieceId) => axios.put(`${baseURL}/piece/revert/${pieceId}`),
    // publishPiece: (draftId, data) => axios.post(`/publish/${draftId}`, data),
    publishPiece: (draftId, draftData, SK) => fetch(`${baseURL}/publishPiece/${draftId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify({ draftId, draftData, SK })
    }),
    publishOverride: (pieceId, draftId, axiosData) => axios.put(`${baseURL}/overridePublish/${pieceId}`, { draftId, axiosData }),
    getPiece: (pieceId) => fetch(`${baseURL}/getPiece/${pieceId}`),
    getPublishedPieces: (token) => fetch(`${baseURL}/getPublishedPieces`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }),
    // getPublished: () => axios.get("/publish"),
    getDrafts: ({ token }) => fetch(`${baseURL}/getDrafts`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        // body: JSON.stringify(username)
    }),
    // deletePiece: (pieceId) => axios.delete(`/piece/${pieceId}`),
    deletePiece: (pieceId, SK) => fetch(`${baseURL}/deleteDraft/${pieceId}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify({ SK })
    }),
    // getRecommended: (page) => axios.post("/recommended", { page }),
    getRecommended: (LastEvaluatedKey) => fetch(`${baseURL}/getRecommended`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ LastEvaluatedKey })
    }),
    getSaved: (page) => axios.post(`${baseURL}/favorite`, { page }),
    getPromoted: () => axios.get(`${baseURL}/promoted`),
    // Subscriptions
    subscribe: (userId) => axios.put(`${baseURL}/subscribe`, { userId }),
    unSubscribe: (userId) => axios.put(`${baseURL}/unSubscribe`, { userId }),
    isSubscribed: (userId) => axios.get(`${baseURL}/isSubscribed/${userId}`),
    getSubscriptions: (page) => axios.post(`${baseURL}/subscriptions`, { page }),
    // userInfo
    getUserInfo: (id) => axios.get(`${baseURL}/userInfo/${id}`),
    // trending
    getTrending: (page) => axios.post("${baseURL}/trending", { page }),
    // auth
    //! Create a route to try sending back cookie with refresh tokens to AWS/BE to validate
    //! Will use authorize and level-2 lamnda functions
    //! Do we need a new fetch for each level?
    signIn: (form) => fetch(`${baseURL}/login`, {
        method: 'POST',
        // credentials: "include",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(form)
    }),
    signUp: (form) => fetch(`${baseURL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(form)
    }),
    getAuth: (headers) => axios.get(`${baseURL}/auth`, headers),
    signOut: () => axios.get(`${baseURL}/auth/signout`),
    refreshTokenLevel1: () => fetch(`${baseURL}/refreshTokenLevel1`, {
        method: 'POST',
        //! "Access-Control-Allow-Credentials": true?
        headers: { 'Content-Type': 'application/json' },
    }),
    checkToken: (token) => fetch(`${baseURL}/checkToken`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }),
}

export default API