import React, { useRef, useState, useEffect } from 'react'
import { useSprings, animated } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { useDrag } from 'react-use-gesture'
import { clamp } from 'lodash'
import ReaderModeInfo from '../ReaderModeInfo/ReaderModeInfo'

import styles from './carousel.module.scss'

export default function Carousel({ content }) {
  //? useRef doesnt cause rerenders

  const scrollPosition = useRef('scroll position');
  const scrollHeight = useRef('scroll height');
  const index = useRef(0)
  const [ref, { height, width }] = useMeasure()
  const isTouchAction = useRef(false); 
  const isTop = useRef(0);
  const isBottom = useRef(0);

  const [props, api] = useSprings(
    content.length,
    i => ({
      y: i * height,
      scale: height === 0 ? 0 : 1,
      display: 'block',
    }),
    [height]
  )

  const handleScroll = (event) => {

    // console.log('handleScroll Ran!');

    scrollPosition.current = Math.round(event.target.scrollTop + window.innerHeight); // innerHeight - px, scrollTop - px (decimal)
    scrollHeight.current = event.target.scrollHeight; // Scroll Height does NOT change

    // console.log('Scroll Position: ', scrollPosition, 'Scroll Height: ', scrollHeight);
    // setIsBottom(false);
    // if (scrollPosition === scrollHeight) { // Detect bottom of a page
    //   console.log("you're at the bottom of the page");
    //   setIsBottom(true);
    // } else {
    //   setIsTouchAction(false);
    //   setIsBottom(false);
    // }
    // if (scrollPosition === window.innerHeight) { // Detect top of a page
    //   setIsTop(true);
    // } else {
    //   setIsTouchAction(false);
    //   setIsTop(false);
    // }
  };

  const bind = useDrag(({ active, movement: [mx, my], direction: [xDir, yDir], distance, cancel }) => {
    // console.log('yDir: ', yDir)
    // console.log('my: ', my)

    let downDrag = Math.sign(yDir) === 1;
    let upDrag = Math.sign(yDir) === -1;
    let isFirstPage = index.current === 0;
    let isLastPage = index.current === content.length - 1;

    //TODO: Figure out if breaking react hooks rules
    //TODO: Is converting to a class component possible, given the custom hooks for the carousel?
    // var scrollPosition = Math.round(ref.current.target.scrollTop + window.innerHeight); // innerHeight - px, scrollTop - px (decimal)
    // var scrollHeight = ref.current.target.scrollHeight; // Scroll Height does NOT change

    
    console.log('Scroll Position: ', scrollPosition.current, 'Scroll Height: ', scrollHeight.current);
    if (scrollPosition.current === scrollHeight.current) { // Detect bottom of a page
      console.log("you're at the bottom of the page");
      isBottom.current = true;
      console.log(isBottom);
    } else {
      isTouchAction.current = false;
      isBottom.current = false;
    }
    console.log('scrollPosition.current: ', scrollPosition.current, 'window.innerHeight: ', window.innerHeight);
    if (scrollPosition.current === window.innerHeight || scrollHeight.current) { // Detect top of a page
      console.log("you're at the top of the page");
      isTop.current = true;
    } else {
      isTouchAction.current = false;
      isTop.current = false;
    }

    // console.log({isTop, downDrag, isFirstPage, isBottom, upDrag, isLastPage});
    
    if (isTop.current && downDrag && !isFirstPage || isBottom.current && upDrag && !isLastPage) {
      isTouchAction.current = true;
      if (active && distance > 2) {
        index.current = clamp(index.current + (yDir > 0 ? -1 : 1), 0, content.length - 1)
        cancel()
      }
      console.log({isBottom});
      api.start(i => {
        // console.log('start')
        // console.log('height: ', height);
        // console.log('i: ', i);
        if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
        //TODO: Figure out why, when switching pages, there is a little tiny space at the top of the next page
        //TODO: And when switching back, the bottom of the previous page is a tiny bit cut off - translate issue with carousel?
        const y = (i - index.current) * height //? Is this how much we are translating 
        const scale = active ? 1 - distance / height / 2 : 1
        return { y, scale, display: 'block' }
      })
    } else {
      isTouchAction.current = false;
    }
  })

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.wrapper} style={isTouchAction || isBottom.current ? {backgroundColor: "red"} : {backgroundColor: "green"}}>
        {props.map(({ y, display, scale }, i) => ( //TODO: Set a hook to set a page number?
          <animated.div onScroll={handleScroll} className={styles.page} {...bind()} key={i} style={{ display, y }}>
          {/* <animated.div className={styles.page} {...bind()} key={i} style={{ display, y }}> */}
            <animated.div style={ { touchAction: isTouchAction ? 'none' : 'auto'}, { scale }}>
              {i === 0 ? <ReaderModeInfo /> : null}
              {/* <h1>{`Page ${i + 1}`}</h1> */}
              <p className={styles.content}>{content[i]}</p>
            </animated.div>
          </animated.div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    showPauseMenu: state.menus.pauseMenu,
    pieces: state.pieces,
    editorState: state.textEditor.editorState,
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.id,
    authorId: state.author.id,
  };
};

