import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faFire,
  faInbox,
  faCheck,
  faUser,
  faICursor
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import styles from "./footer.module.scss";
import readIcon from '../../assets/images/read_icon.png'
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ShortTextIcon from '@mui/icons-material/ShortText';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import InboxIcon from '@mui/icons-material/Inbox';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import PersonIcon from '@mui/icons-material/Person';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { connect } from "react-redux";
import { isSignedIn, updateButtonClickedAuth } from "../../actions";
import { grey } from "@mui/material/colors";

const Footer = (props) => {
  const [value, setValue] = useState(0);
  const location = useLocation();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000"
      }
    }
  });

  // const [icons, setIcons] = useState(
  //   {
  //     icons: [
  //       {
  //         icon: <ShortTextIcon />,
  //         id: "homeIcon",
  //         link: "/",
  //         active: false,
  //         color: null,
  //         title: 'Read'
  //       },
  //       {
  //         icon: <WhatshotIcon />,
  //         id: "faFire",
  //         link: "/trending",
  //         active: false,
  //         color: null,
  //         title: 'Trending'
  //       },
  //       {
  //         icon: <InboxIcon />,
  //         id: "faInbox",
  //         link: "/subscriptions",
  //         active: false,
  //         color: null,
  //         title: 'Subscriptions'
  //       },
  //       {
  //         icon: <CheckIcon />,
  //         id: "faCheck",
  //         link: "/saved",
  //         active: false,
  //         color: null,
  //         title: 'Saved'
  //       },
  //       {
  //         icon: <CreateIcon />,
  //         id: "faICursor",
  //         // link: "/saved", //TODO: hook this up to newPiece and put it as a protected route in app.js
  //         active: false,
  //         color: null,
  //         title: 'Write'
  //       },
  //       {
  //         icon: <CreateIcon />,
  //         id: "faICursor",
  //         // link: "/saved", //TODO: hook this up to newPiece and put it as a protected route in app.js
  //         active: false,
  //         color: null,
  //         title: 'Write'
  //       },
  //       {
  //         icon: <PersonIcon />,
  //         id: "faUser",
  //         link: "/account",
  //         active: false,
  //         color: null,
  //         title: 'Account'
  //       },
  //     ],
  //   });

  useEffect(() => {
    console.log("URL from props from footer: ", props.URL, location);
    setActiveMenuItem();
  }, [location])

  const clickIcon = (id) => {
    let icons = icons.map((icon) => {
      return { ...icon, active: icon.id === id ? true : false };
    });

    setIcons({ icons });
  };

  const setButtonClicked = () => {
    props.updateButtonClickedAuth("write")
  }

  const setActiveMenuItem = () => {
    //0 is first item (Read), 1, is second (Search), 2 is third (Write)
    if (location.pathname === "/") setValue(0);
    if (location.pathname === "/search") setValue(1);
    if (location.pathname === "/writersMode") setValue(2);
    if (location.pathname === "/signIn") setValue(null);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log('bottom nav new value: ', newValue);
  };

  // TODO: should have a unique key prop
  return (
    <div id={styles.footer}>
      <Box>
        <ThemeProvider theme={theme}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={handleChange}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            //   console.log('bottom nav values: ', newValue);
            // }}
          >
            <BottomNavigationAction label="Read" component={Link} to="/" icon={<ShortTextIcon />} />
            {/* <BottomNavigationAction label="Trending" component={Link} to="/trending" icon={<WhatshotIcon />} />
          <BottomNavigationAction label="Subscriptions" component={Link} to="/subscriptions" icon={<InboxIcon />} />
          <BottomNavigationAction label="Saved" component={Link} to="/saved" icon={<CheckIcon />} /> */}
            <BottomNavigationAction label="Search" component={Link} to="/search" icon={<SearchIcon />} />
            <BottomNavigationAction label="Write" component={Link} to="/writersMode" onClick={setButtonClicked} icon={<CreateIcon />} />
            {/* <BottomNavigationAction label="Account" component={Link} to="/account" icon={<PersonIcon />} /> */}
          </BottomNavigation>
        </ThemeProvider>
      </Box>
    </div>
  );
}
export default connect(null, { isSignedIn, updateButtonClickedAuth })(Footer);
