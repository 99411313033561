import React, { Component, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import API from '../../../utils/api';
import { setAuthor } from '../../../actions/author';

import styles from "./userInfo.module.scss";
function UserInfo(props) {
  const params = useParams();

  // useEffect(() => {
  //   API.getUserInfo(props.authorId)
  //     .then(({data})=>{
  //       props.setAuthor(data);
  //     })
  // }, []) 

  return (
    <>
      {/* TODO - BREAK THIS OUT INTO DIFFERENT COMPONENTS */}
      <div id={styles.userInfoContainer}>
        <div id={styles.userDetails} className="row">
          <div id={styles.pieces} className="four columns">
            <p id={styles.pieceCountLabel}>Pieces</p>
            <p id={styles.pieceCount}>{props.pieceCount}</p>
          </div>
          <h1 id={styles.name} className="four columns">
            {props.userName}
          </h1>
          <div id={styles.subs} className="four columns">
            <p id={styles.subCountLabel}>Subscribers</p>
            <p id={styles.subCount}>{props.subscriberCount}</p>
          </div>
        </div>
        <div id={styles.userBio}>
          <p>
            Just a guy writing some things. I have written for x publications
            and now am doing my own thing.
          </p>
        </div>
        {/* This ID is unused! */}
        <div id={styles.editProfileButtonContainer}>
          <button
            style={{ backgroundColor: props.backgroundColor, color: "white" }}
            id={styles.editOrSubButton}
            onClick={props.action}
          >
            {props.buttonName}
          </button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userName: state.author.username,
    pieceCount: state.author.pieceCount,
    subscriberCount: state.author.subscriberCount,
  };
};

export default connect(mapStateToProps, { setAuthor })(
  UserInfo
);
