import { UP_VOTE, DOWN_VOTE, HAS_VOTE, UPDATE_UP_VOTE, UPDATE_DOWN_VOTE } from "./types";
import { pauseMenu } from "./menus";
import API from './../utils/api'
import { text } from "@fortawesome/fontawesome-svg-core";
import { Redirect } from "react-router-dom";

// ? Should we pass in id?
// ? DELETE, put in signIn action
export const checkVote = () => async (dispatch, getState) => {
  let state = await getState();
  const { data } = await API.getVote(state.pieces.currentPiece._id);
  console.log('In checkVote - State: ', state);
  console.log('In checkVote - data: ', data);
  return data;
  // dispatch(hasVote(data));
}

export const upVote = () => {
  return {
    type: UP_VOTE, // sets up vote on the UI
  };
};

export const downVote = () => {
  return {
    type: DOWN_VOTE, // sets down vote on the UI
  };
};

export const updateUpVote = () => {
  return {
    type: UPDATE_UP_VOTE, // resets/unvote up vote
  };
};

export const updateDownVote = () => {
  return {
    type: UPDATE_DOWN_VOTE, // resets/unvote down vote
  };
};

export const hasVote = hasVote => {
  console.log('hasVote: ', hasVote);
  return {
    type: HAS_VOTE,
    payload: hasVote,
  };
};

// TODO: Decide when to setstate, have a delay but ensure db updates or have UI feed be immediate but potentially have the user enter a state in which the db has not been updated.
// Can put functions into separate actions, to account for voting then immediately leaving the piece.
// TODO: Pass in object instead to clean this up a little?
export const updateVotes = (unVote, vote) => async (dispatch, getState) => {
  console.log('Inside updateVotes');
  switch (vote) {
    case 'upVote':
      dispatch(upVote()); // up vote on UI
      break;
    case 'downVote':
      dispatch(downVote()); // down vote on UI
      break;
    case 'updateUpVote':
      dispatch(updateUpVote()); // resets up vote on UI
      break;
    case 'updateDownVote':
      dispatch(updateDownVote()); // resets down vote on UI
      break;
  }
  // dispatch(pauseMenu());
  try {
    let state = await getState();
    console.log('updateVotes pieceId: ', state.pieces.currentPiece._id);
    await unVote && API.unVote(unVote, state.pieces.currentPiece._id)
    // Only make call to /vote when vote is upVote or downVote, substracts 1 extra if we dont specify
    await (vote === 'upVote' || vote === 'downVote') && API.vote(vote, state.pieces.currentPiece._id)
    console.log('Saved to the DB!!')
  }
  catch (err) {
    console.log(err)
  }
}

export const handleVote = (vote) => async (dispatch, getState) => {
  // dispatch(pauseMenu());
  try {
    let {
      vote: { hasVote }
    } = await getState();
    if (vote === 'upVote' && hasVote.upVote) {
      // if voted up already remove upvote
      dispatch(updateVotes('upVote', 'updateUpVote')); // (unVote, vote)
    } else if (vote === 'upVote' && hasVote.downVote) {
      // remove downvote and register upvote
      dispatch(updateVotes('downVote', 'upVote'));
    } else if (vote === 'downVote' && hasVote.upVote) {
      dispatch(updateVotes('upVote', 'downVote'));
    } else if (vote === 'downVote' && hasVote.downVote) {
      dispatch(updateVotes('downVote', 'updateDownVote'));
    } else {
      dispatch(updateVotes(null, vote));
    }
  }
  catch (error) {
    console.log(error);
  }
}