import React, { Component } from "react";
import { connect } from "react-redux";
import {
  closeModal,
  closeDuplicatePublishModal,
  publishOverride,
} from "../../actions";
import styles from "./index.module.scss";

export class DuplicatePublishModal extends Component {
  state = {};

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  //for a more complex close modal function, can put logic here
  close = () => this.props.closeModal;

  render() {
    console.log(this.state);

    if (this.props.isSignedIn) {
      return <this.props.destination />;
    } else {
      return (
        <div className={styles.bgModal}>
          <div className={styles.modalContents}>
            <h4>You already have a published piece with this title</h4>
            <h4>
              Do you want to rename this piece or override the existing one?
            </h4>
            <input
              onClick={() => this.props.publishOverride(this.props.redirect)}
              className={styles.publishAndOverrideButton}
              value="Publish and Override"
            />
            <div className={styles.closeDuplicateModal}>
              <a href="#" onClick={this.props.closeDuplicatePublishModal}>
                Nevermind, let me rename it
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  closeDuplicatePublishModal,
  publishOverride,
})(DuplicatePublishModal);
