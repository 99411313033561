import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  createEditor,
  changeEditor,
  saveEditorTitle,
  saveEditorBody,
  clearEditor,
  savePiece,
} from "../../actions";
import { useParams } from "react-router-dom";
import API from '../../utils/api';

import styles from "./TextEditor.module.scss";

// Lots of leftover code from Draft.js

function TextEditor(props) {
  let refEditor = useRef("editor");
  console.log("Use Params: ", useParams());
  let params = useParams();

  // This will create an infinite loop!
  // To fix, add dependency on params.id.
  useEffect(() => {
    console.log("Inside UseEffect");
    console.log("id: ", params.id);
    console.log("pieceId: ", props.pieceId);

    async function fetchData() {
      if (params.id) {
        let response = await API.getPiece(params.id);
        let { data } = await response.json();
        console.log('piece from TextEditor: ', data);
        props.createEditor(data);
        // props.createEditor(
        //   props.editor.filter((draft) => draft.pieceId == params.id)[0]
        // );
        // } else {
        //   console.log("else ran!");
        //   props.createEditor(
        //     props.editor.filter((draft) => draft.pieceId == props.pieceId)[0]
        //   );
      }
    }
    
    fetchData();

    return () => {
      // Ran after leaving the component, clean up
      // props.clearEditor();
    };
  }, []);

  const onChange = (e) => {
    // const value = refEditor.current;
    // props.changeEditor(value);
    props.saveEditorBody(e.target.value);
    props.changeEditor();
  };

  return (
    // Unused classname.
    <div className="editorContainer">
      <div>
        <form>
          <input
            className={styles.titleInput}
            type="text"
            value={props.title}
            name="title"
            placeholder="Title"
            onChange={(e) => {
              props.saveEditorTitle(e.target.value);
              props.changeEditor();
            }}
          />
        </form>
      </div>
      <div style={{ width: "100%", minHeight: "70vh", margin: "0 auto" }}>
        <textarea
          style={{ width: "100%", height: "74vh" }}
          id={styles.editor}
          placeholder="Body"
          onChange={onChange}
          value={props.editorState}
        // ref={refEditor} //! is this better performance wise??
        />
      </div>
    </div>
  );
}
// }

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

const mapStateToProps = (state) => ({
  editorState: state.textEditor.editorState,
  newPiece: state.textEditor.newPiece,
  title: state.textEditor.title,
  editor: state.pieces.drafts,
  pieceId: state.pieces.currentPiece._id,
});

export default connect(mapStateToProps, {
  createEditor,
  changeEditor,
  saveEditorTitle,
  saveEditorBody,
  clearEditor,
  savePiece,
})(TextEditor);
