import { combineReducers } from "redux";
import someReducer from "./someReducer";
import authReducer from "./authReducer";
import menusReducer from "./menusReducer";
import textEditorReducer from "./textEditorReducer";
import writersModeReducer from "./writersModeReducer";
import piecesReducer from "./piecesReducer";
import voteReducer from "./voteReducer";
import authorReducer from "./authorReducer";
import subscriptionsReducer from "./subscriptionsReducer";
import savedReducer from "./savedReducer";

export default combineReducers({
  someStateData: someReducer,
  auth: authReducer,
  menus: menusReducer,
  textEditor: textEditorReducer,
  writersMode: writersModeReducer,
  author: authorReducer,
  pieces: piecesReducer,
  subscriptions: subscriptionsReducer,
  saved: savedReducer,
  vote: voteReducer,
});
