import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "../../piece/Card/Card";
import { Link } from "react-router-dom";

import styles from "../readersMode.module.scss";

const Recommended = (props) => {
  return (
    <>
      {/* TODO: remove unneeded check for published */}
      {props.recommended.Items.map((recommended) =>
        recommended.published ? (
          <Link
            className="readersModeLink"
            to={`/piece/${recommended.pieceId}`}
          >
            <Card
              title={recommended.title}
              created={recommended.created}
              author={recommended.userName}
              pageCount={recommended.pageCount}
            />
          </Link>
        ) : (
          ""
        )
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ recommended: state.pieces.recommended });

export default connect(mapStateToProps, {})(Recommended);
