import { SHOW_PAUSE_MENU, SHOW_PAUSE_MENU_SUBSCRIBE_BUTTON , SHOW_PREVIEW_PAUSE_MENU, PAUSE_MENU_LEAVE, HAS_SAVED } from "./types";
import { text } from "@fortawesome/fontawesome-svg-core";
import { Redirect } from "react-router-dom";

export const pauseMenu = () => {
  return {
    type: SHOW_PAUSE_MENU,
  };
};

export const previewPauseMenu = () => {
  return {
    type: SHOW_PREVIEW_PAUSE_MENU,
  };
};

export const pauseMenuLeave = () => {
  return {
    type: SHOW_PAUSE_MENU,
  };
};

export const previewPauseMenuLeave = () => {
  return {
    type: SHOW_PREVIEW_PAUSE_MENU,
  };
};

export const handleSaveUI = (hasSaved) => { // Pause Menu Save
  return {
    type: HAS_SAVED,
    payload: hasSaved,
  };
};

export const showPauseMenuSubscribeButton = () => ({ type: SHOW_PAUSE_MENU_SUBSCRIBE_BUTTON })