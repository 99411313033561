import React, { Component } from "react";
import { connect } from "react-redux";
import {
  authorSettings,
  clearEditor,
  revertPieceToDraft,
  revertPublishedPiece,
} from "../../actions";
import styles from "./index.module.scss";

export class RevertDraftModal extends Component {
  state = {};

  //for a more complex close modal function, can put logic here
  close = () => {
    this.props.revertPieceToDraft();
    this.props.authorSettings();
  };

  handleRevert = () => {
    this.props.revertPublishedPiece(this.props.pieceId, this.props.redirect);
  };

  render() {
    console.log(this.state);

    if (this.props.isSignedIn) {
      return <this.props.destination />;
    } else {
      return (
        <div className={styles.bgRevertToDraftModal}>
          <div className={styles.revertToDraftModalContents}>
            <h4>
              Are you sure you want to unpublish this piece and <br /> revert it
              to a draft?
            </h4>
            <input
              onClick={this.handleRevert}
              className={styles.revertButton}
              value="Revert To Draft"
            />
            <div className={styles.closeRevertModalLink}>
              <a href="#" onClick={this.close}>
                Nevermind, <br /> take me back
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ pieceId: state.pieces.currentPiece._id });

export default connect(mapStateToProps, {
  authorSettings,
  clearEditor,
  revertPieceToDraft,
  revertPublishedPiece,
})(RevertDraftModal);
