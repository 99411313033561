import React, { Component, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { signIn, signUp, closeModal } from "../../actions";
import styles from "./index.module.scss";
// import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

//TODO: DELETE THIS
const AuthModal = (props) => {
  let location = useLocation();
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  // const [confirmationCode, setConfirmationCode] = useState(null);

  const [state, setState] = useState({
    email: "",
    username: "",
    password: "",
    signUp: false,
    confirmationCode: null,
  })

  //! Move this app.js so we could have this listen for auth for entire app
  //! See if the logs from sign in show when logging in
  //! Not showing signIn logs because it is redirecting away from authModal(this component) and logs are lost
  //! Figure out where to store the various tokens

  useEffect(() => {
    //   const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
    //     console.log('data before switch from authModal: ', data);
    //     switch (event) {
    //       case "signIn":
    //         console.log('data from authModal: ', data)
    //         setUser(data);
    //         break;
    //       case "signOut":
    //         setUser(null);
    //         break;
    //       case "customOAuthState":
    //         setCustomState(data);
    //     }
    //   });

    //   Auth.currentAuthenticatedUser()
    //     .then(currentUser => { 
    //       console.log('data from currentAuthenticatedUser from authModal: ', currentUser);
    //       setUser(currentUser) 
    //     })
    //     .catch(() => console.log("Not signed in"));

    // return unsubscribe;
  }, []);

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const signIn = async () => { //TODO: navigate to account page?
    //! use useLocation() hook to get history and navigate to most recent item?
    //! What data structure is the history and property under?
    console.log('location from auth modal signIn(): ', location);
    try {
      console.log('state from signIn(): ', state);
      // const user = await Auth.signIn(state.userName, state.password);
      console.log('user from signIn(): ', user);
    } catch (error) {
      console.log('error signing in: ', error);
    }

    props.signIn(state, props.isSignedIn); //! call auth.signIn()?
  };

  const signUp = async () => { //TODO: navigate to account page?
    // props.signUp(state); //! //! call auth.signUp()?
    try {

      props.signUp({
        username: state.username,
        username: state.email,
        password: state.password
      })
      // const { user } = await Auth.signUp({

      // username: state.userName,
      // password: state.password,
      //   attributes: {
      //     email: state.email,          // optional
      //     username: state.userName,
      //     // other custom attributes 
      //   },
      //   autoSignIn: { // optional - enables auto sign in after user is confirmed
      //     enabled: true,
      //   }
      // });
      setShowConfirmation(true);
      console.log('user from signUp(): ', user);
    } catch (error) {
      console.log('error signing up:', error);
    }
  };

  const handleSwitch = () => {
    // setShowConfirmation(false); //! look into when we should show this!!
    if (state.signUp) {
      setState({ ...state, signUp: false });
    } else {
      setState({ ...state, signUp: true });
    }
  };

  const handleSignUpConfirmation = () => {
    console.log('state from handleSignUpConfirmation(): ', state);
    // Auth.confirmSignUp(state.userName, state.confirmationCode).then((x) => {
    //   console.log('confirmSignUp .then ran ', x);
    //   navigate('/account');
    //   setShowConfirmation(false);
    // });
  }

  const close = () => props.closeModal;

  return (
    <div className={styles.bgModal}>
      <div className={styles.modalContents}>
        {/* <div class="close" onClick={props.closeModal}>+</div> */}
        <p style={{ color: "red", fontSize: "25px", fontWeight: "600" }}>
          Writ.ink
        </p>

        {state.signUp ? (
          <>
            <p>Create a new account</p>
            <input
              type="text"
              name="username"
              placeholder="Username"
              // maxlength="20"
              value={state.username}
              onChange={onChange}
            />
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={state.email}
              onChange={onChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={state.password}
              onChange={onChange}
            />
            <input
              className={styles.button}
              onClick={signUp}
              onChange={signUp}
              value="Create Account" d
            />

            <br />
            <a href="#" onClick={handleSwitch}>
              Sign In Instead
            </a>
          </>
        ) : (
          <>
            <p>Please sign in to your account</p>
            <input
              type="text"
              placeholder="Username"
              name="username"
              // maxlength="20"
              value={state.userName}
              onChange={onChange}
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={state.password}
              onChange={onChange}
            />
            {/* TODO: Make a button instead for an input?  */}
            <input
              className={styles.button}
              onClick={signIn}
              onChange={signIn}
              value="Sign in"
            />
            <br />
            <a href="#" onClick={handleSwitch}>
              Create a new account
            </a>
            <br />
          </>
        )}
        <a
          href="#"
          style={{ float: "right", marginTop: "10px" }}
          onClick={props.closeModal}
        >
          Close
        </a>
        {/* <button onClick={() => Auth.signIn()}>Open Hosted UI</button>
        <button onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}>Open Google</button>
        <button onClick={() => Auth.signOut()}>Sign Out</button> */}
        <div>{user && user.getUsername()}</div>
      </div>
      {/* //TODO: put in new component? */}
      {showConfirmation && <div>
        <input
          type="number"
          placeholder="Enter Confirmation Code"
          name="confirmationCode"
          value={state.confirmationCode}
          onChange={onChange}
        />
        <input
          className={styles.button}
          onClick={handleSignUpConfirmation}
          value="submit code"
        />
      </div>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
});

export default connect(mapStateToProps, { signIn, signUp, closeModal })(
  AuthModal
);
