import React, { Component } from "react";
import { connect } from "react-redux";
import {
  authorSettings,
  deletePiece,
  deletePieceModal,
  clearEditor,
} from "../../actions";
import styles from "./index.module.scss";

export class DeleteDraftModal extends Component {
  state = {};

  //for a more complex close modal function, can put logic here
  close = () => {
    this.props.deletePieceModal();
    this.props.authorSettings();
  };

  handleDelete = () => {
    this.props.deletePiece(
      this.props.currentPiece._id,
      this.props.currentPiece.draft,
      this.props.redirect
    );
    this.props.deletePieceModal();
  };

  render() {
    console.log(this.state);

    if (this.props.isSignedIn) {
      return <this.props.destination />;
    } else {
      return (
        <div className={styles.bgDeletePieceModal}>
          <div className={styles.deletePieceModalContents}>
            {/* //TODO: Do something like this? <h4>Are you sure you want to <br /> delete this {this.props.currentPiece.draft ? 'draft' : 'piece'}?</h4> */}
            <h4>
              Are you sure you want to <br /> delete this piece?
            </h4>
            <input
              onClick={this.handleDelete}
              className={styles.deletePieceButton}
              value="Delete Piece"
            />
            <div className={styles.closeDeletePieceModalLink}>
              <a href="#" onClick={this.close}>
                Nevermind, <br /> take me back
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  currentPiece: state.pieces.currentPiece,
});

export default connect(mapStateToProps, {
  authorSettings,
  deletePiece,
  deletePieceModal,
  clearEditor,
})(DeleteDraftModal);
