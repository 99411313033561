import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// Redux Setup
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
// Reducers
import reducers from "./reducers";
// Amplify
// import { Amplify } from 'aws-amplify';
// import awsConfig from './aws-exports'; //! maybe add role per https://docs.aws.amazon.com/amplify/latest/userguide/amplify-config-autogeneration.html

// const isLocalhost = Boolean(
//   window.location.hostname === 'localhost' ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === '[::1]' ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );

// // Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [
//   localRedirectSignIn,
//   productionRedirectSignIn,
// ] = awsConfig.oauth.redirectSignIn.split(',');

// const [
//   localRedirectSignOut,
//   productionRedirectSignOut,
// ] = awsConfig.oauth.redirectSignOut.split(',');

// const updatedAwsConfig = {
//   ...awsConfig,
//   oauth: {
//     ...awsConfig.oauth,
//     redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
//     redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
//   }
// }

// Amplify.configure(updatedAwsConfig);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Redux Dev Tool

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//   trace: true, // (action) => { return ‘trace as string’; }
//   traceLimit: 25,
// }) || compose;

//Creating Central Store for State
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
