import { AUTHOR_SETTINGS, DELETE_PIECE, PIECES_GET, PROMOTED_GET, CLEAR_RECOMMENDED, DELETE_DRAFT_CONFIRMATION, DRAFTS_GET, PIECE_SAVE, RECOMMENDED_GET, PIECE_PUBLISH, PIECE_DUPLICATE, PUBLISH_DUPLICATE, PUBLISH_CONFIRMATION, EDITOR_CLEAR, OLD_DRAFT_DELETE, TRENDING_GET, REVERT_TO_DRAFT, UNSUBSCRIBE_CONFIRMATION, RESET_STORE } from "../actions/types";

const INTIAL_STATE = {
  currentPiece: {
    _id: null,
    draft: null,
  },
  pieces: [],
  promoted: [],
  drafts: [],
  recommended: {Items: []},
  trending: { totalPages: 0, pieces: [] },
  // trending: [],
  isDuplicate: false,
  isPublishedDuplicate: false,
  oldDraftToDeleteId: null,
  showPublishedConfirmation: false,
  showDeleteDraftConfirmation: false,
  showAuthorSettings: false,
  showRevertToDraft: false,
  showDeletePiece: false,
  showUnSubscribeModal: false,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case PIECE_SAVE:
    case PIECE_PUBLISH:
    case EDITOR_CLEAR:
      return {
        ...state,
        currentPiece: action.payload
      };
    case PIECES_GET:
      return {
        ...state,
        pieces: action.payload
      };
    case PROMOTED_GET:
      return {
        ...state,
        promoted: action.payload
      };
    case DRAFTS_GET:
      return {
        ...state,
        drafts: action.payload
      };
    case RECOMMENDED_GET:
      return {
        ...state,
        recommended: {...state.recommended, ...action.payload, Items: [...state.recommended.Items, ...action.payload.Items]} //! drill down to Items? new page overrides previous
      };
    case TRENDING_GET:
      return {
        ...state,
        trending: { totalPages: action.payload.totalPages, pieces: [...state.trending.pieces, ...action.payload.pieces] }
        // trending: [...state.trending, ...action.payload]
      };
    case CLEAR_RECOMMENDED:
      return {
        ...state,
        recommended: []
      };
    case PIECE_DUPLICATE:
      return {
        ...state,
        isDuplicate: !state.isDuplicate
      }
    case AUTHOR_SETTINGS:
      return {
        ...state,
        showAuthorSettings: !state.showAuthorSettings
      }
    case REVERT_TO_DRAFT:
      return {
        ...state,
        showRevertToDraft: !state.showRevertToDraft
      }
    case UNSUBSCRIBE_CONFIRMATION:
      return {
        ...state,
        showUnSubscribeModal: !state.showUnSubscribeModal
      }
    case DELETE_PIECE:
      return {
        ...state,
        showDeletePiece: !state.showDeletePiece
      }
    case PUBLISH_CONFIRMATION:
      return {
        ...state,
        showPublishedConfirmation: !state.showPublishedConfirmation
      }
    case PUBLISH_DUPLICATE:
      return {
        ...state,
        isPublishedDuplicate: !state.isPublishedDuplicate
      }
    case OLD_DRAFT_DELETE:
      return {
        ...state,
        oldDraftToDeleteId: action.payload.oldDraftToDeleteId
      }
    case DELETE_DRAFT_CONFIRMATION:
      return {
        ...state,
        showDeleteDraftConfirmation: action.payload.showDeleteDraftConfirmation
      }
    case RESET_STORE:
      return {
        ...INTIAL_STATE,
      }
    default:
      return state;
  }
};
