import React, { Component } from "react";

import styles from "./Collections.module.scss";

export default class Collections extends Component {
  render() {
    return (
      <div className={styles.topAndBottomDivContainer}>
        <div className={styles.topDiv}>
          <div>Upvoted</div>
          <div>1K</div>
        </div>
        <div className={styles.bottomDiv}></div>
      </div>
    );
  }
}
