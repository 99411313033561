import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { authorSettings } from "../../actions";
import { pauseMenu } from "../../actions/menus";
import CardInfo from "../piece/CardInfo/CardInfo";

import styles from "./ReaderModeInfo.module.scss";

function ReaderModeInfo(props) {

  const handleAuthorSettings = () => {
    props.pauseMenu();
    props.authorSettings();
  };

  return (
    <>
      <h2 className={styles.readersModeTitle}>
        {props.pieces.currentPiece.title}
      </h2>
      {/* //TODO: Figure out how to make shorter */}
      <Link
        data-disablePauseMenu={true}
        className={styles.readersModeAuthor}
        to={
          props.userId == props.authorId
            ? "/account"
            : `/authorPage/${props.pieces.currentPiece.authorName._id}`
        }
      >
        {props.pieces.currentPiece.authorName.username}
      </Link>
      {props.userId == props.authorId && (
        <button
          onClick={handleAuthorSettings}
          className={styles.authorSettingsButton}
        >
          <FontAwesomeIcon
            id={styles.authorSettingsIcon}
            icon={faPencilAlt}
          />
          Author Settings
        </button>
      )}
      <CardInfo created={props.pieces.currentPiece.created} pageCount={props.pageCount}/>
    </>);
}

const mapStateToProps = (state) => {
  return {
    pieces: state.pieces,
    userId: state.auth.id,
    authorId: state.author.id,
  };
};

export default connect(mapStateToProps, { pauseMenu, authorSettings})(
  ReaderModeInfo
);
