import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { getTrending } from "../actions";
import API from '../utils/api';
import Card from '../components/piece/Card/Card';
import { Link } from "react-router-dom";
import useWindowScrollPosition from "./../hooks/useWindowScrollPosition";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import styles from "./trending.module.scss";

const Trending = (props) => {

  let [page, setPage] = useState(1);

  const scrollRef = useBottomScrollListener(() => handleEndOfPage());
  useWindowScrollPosition("Trending_ScrollTop", true, scrollRef);

  useEffect(() => {
    // console.log('The trending array exists: ', props.trending.pieces.length == 0);
    // if (props.trending.length == 0) { //trending empty 
    if (props.trending.pieces.length == 0) { //trending empty 
      localStorage.setItem('Trending_ScrollTop', 0);
      // console.log('The trending array exists (INSIDE IF): ', props.trending.pieces.length == 0);
      props.getTrending(page);
    }
  }, [])
  // TODO: move to utils
  const handleEndOfPage = () => {
    // TODO: show loading spinner and make fetch request to api
    if (page <= 1) {
      page = page + 1;
      props.getTrending(page);
      setPage(page); // when setPage finishes turn off spinner/loader
    }
  }

  return (
    <>
      <div ref={page <= props.trending.totalPages && page <= 1 && scrollRef ? scrollRef : null} className={styles.trendingTab}>
        {/* // ? Make new component to combine with saved page? */}
        <h1 className="sectionHeaders">
          Trending
        </h1>
        {/* {props.trending.map((trending) => ( */}
        {props.trending.pieces.map((trending) => (
          <Link className="readersModeLink" to={`/piece/${trending._id}`}>
            <p>{page}</p>
            <Card
              title={trending.title}
              author={trending.authorId.username}
              created={trending.created}
            />
          </Link>
        ))}
        {page > 1 && <h3>You have reached the end of the list</h3>}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  console.log(state);
  return {
    trending: state.pieces.trending,
  }
}
export default connect(mapStateToProps, { getTrending })(Trending);
