import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { hideMainHeader, hideFooter, loadEditor, getDrafts } from "../actions";
import { previewPauseMenu } from "../actions/menus";
import CardInfo from "../components/piece/CardInfo/CardInfo";
import PreviewPauseMenu from "./PreviewPauseMenu";
import ReaderModeContent from "../components/ReaderModeContent/ReaderModeContent";

import styles from "./ReadersMode.module.scss";

const PreviewMode = (props) => {
  useEffect(() => {
    props.hideMainHeader();
    props.hideFooter();
    props.loadEditor(props.currentPiece);
    props.getDrafts();
    // TODO:Call backend to get chopped editor state
  }, []);

  return (
    <>
      {props.currentPiece.title ? (
        // <div
        //   className={styles.readersModeContainer}
        //   onClick={props.previewPauseMenu}
        // >
        //   <h2 className={styles.readersModeTitle}>
        //     {props.currentPiece.title}
        //   </h2>
        //   <Link
        //     className={styles.readersModeAuthor}
        //     onClick={props.previewPauseMenu}
        //   >
        //     {props.username}
        //   </Link>
        //   <CardInfo />
        //   <p className={styles.readersModeContent}>
        //     {/* // TODO: Update editorState using common utils function */}
        //     {props.editorState}
        //   </p>
        //   <hr />
        // </div>
        <div
          className={styles.readersModeContainer}
          onClick={props.previewPauseMenu}
        >
          <ReaderModeContent editorState={props.editorState} />
        </div>
      ) : null}
      {props.showPreviewPauseMenu && <PreviewPauseMenu />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showPreviewPauseMenu: state.menus.previewPauseMenu,
    currentPiece: state.pieces.currentPiece,
    editorState: state.textEditor.editorState,
    username: state.auth.username,
    //TODO: rename username to userName everywhere
  };
};

export default connect(mapStateToProps, {
  previewPauseMenu,
  hideMainHeader,
  hideFooter,
  loadEditor,
  getDrafts,
})(PreviewMode);
