import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Popover from "react-tiny-popover";
import { publishConfirmation, deleteDraftConfirmation } from "../../actions";
import { previewPauseMenu } from "../../actions/menus";

import styles from "./PreviewPauseMenuHeader.module.scss";

class PreviewPauseMenuHeader extends Component {
  handlePublish = () => {
    this.props.publishConfirmation();
  };

  handleDelete = () => {
    this.props.deleteDraftConfirmation();
  };

  handlePreviewPauseMenuLeave = () => {
    this.props.previewPauseMenu();
    window.history.back(); // TODO: should we disable the back button on the browser?
  };

  render() {
    return (
      <div className={styles.navbar} id={styles.pauseMenuHeader}>
        <div className={styles.pauseMenuHeaderContentContainer}>
          {/* pauseMenuHeaderIcon is unused!
        To use this globally, use a regular classname 
        and add it to the global stylesheet */}
          <a
            className={classnames(
              styles.noSelect,
              styles.backToAccount,
              styles.pauseMenuHeaderIcon
            )}
            onClick={this.handlePreviewPauseMenuLeave}
          >
            {" "}
            {/*FIXME: put showFooter onClick here*/}
            <FontAwesomeIcon
              className={styles.arrowLeftIcon}
              icon={faArrowLeft}
            />
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  saveStatus: state.textEditor.status,
});

export default connect(mapStateToProps, {
  previewPauseMenu,
  publishConfirmation,
  deleteDraftConfirmation,
})(PreviewPauseMenuHeader);
