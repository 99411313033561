import { SIGN_IN, SIGN_OUT, IS_SIGNED_IN, IS_LOGGED_IN, SET_USER, SIGN_UP, BUTTON_CLICKED, SUBSCRIBE, UNSUBSCRIBE, RESET_STORE } from '../actions/types';

const INTIAL_STATE = {
  isSignedIn: false,
  isLoggedIn: false,
  username: null,
  id: null,
  showModal: null,
  buttonClicked: null,
  isSubscribed: false,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_UP:
    case SIGN_IN:
      return { ...state, ...action.payload };
    case SIGN_OUT:
      return { ...state, ...action.payload };
    case IS_SIGNED_IN:
      return { ...state, isSignedIn: action.payload }
    case IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload }
    case SET_USER:
      return { ...state, username: action.payload }
    case BUTTON_CLICKED:
      return { ...state, buttonClicked: action.payload }
    case UNSUBSCRIBE:
      return {
        ...state,
        isSubscribed: false
      }
    case SUBSCRIBE:
      return {
        ...state,
        isSubscribed: true
      }
    case RESET_STORE:
      return {
        ...INTIAL_STATE,
      }
    default:
      return state;
  }
};
