import { EDITOR_CREATE, EDITOR_CHANGE, EDITOR_STATUS, EDITOR_SAVE_TITLE, EDITOR_DB_TITLE, EDITOR_DB, EDITOR_INTERVAL, RESET_STORE } from "../actions/types";

const INTIAL_STATE = {
  editorState: "",
  pieces: [],
  saved: null,
  title: "",
  titleDb: "",
  status: "",
  interval: null,
  editorDb: null,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case EDITOR_CREATE:
      return { ...state, editorState: action.payload.editorState };
    case EDITOR_DB:
      return { ...state, editorDb: action.payload.editorDb };
    case EDITOR_CHANGE:
      return { ...state, editorState: action.payload };
    case EDITOR_SAVE_TITLE:
      return {
        ...state, title: action.payload
      }
    case EDITOR_DB_TITLE:
      return {
        ...state, titleDb: action.payload
      }
    case EDITOR_STATUS:
      return { ...state, status: action.payload };
    case EDITOR_INTERVAL:
      return { ...state, interval: action.payload };
    case RESET_STORE:
      return {
        ...INTIAL_STATE,
      }
    default:
      return state;
  }
};
