import React, { Component } from "react";
import { connect } from "react-redux";
import { clearEditor } from "../actions";
import UserDrafts from "../components/features/UserDrafts/UserDrafts";
import UserPublished from "../components/features/UserPublished/UserPublished";
import UserCollections from "../components/features/UserCollections/UserCollections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import styles from "./WritersMode.module.scss";

class WritersMode extends Component {
  state = { isPopoverOpen: false };

  componentDidMount() {
    this.props.clearEditor();
  }

  render() {
    return (
      <>
        <Button component={Link} to="/newPiece" variant="outlined" startIcon={<AddIcon />} sx={{ color: "#D32F2F", borderColor: "#D32F2F", marginLeft: "24px", marginBottom: "64px" }}>New Piece</Button>
        <UserDrafts />
        <UserPublished />
        {/* <UserCollections /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { clearEditor })(WritersMode);
